<template>
  <header class="header">
    <div class="header-left">
      <div class="menu__element-wrapper ">
        <button v-tooltip="tooltips.FULL_SCREEN" class="menu__element full" @click="fullScreen">
          <img src="@/assets/icons/game/full-screen.svg" alt="burger" class="menu__icon"/>
        </button>
      </div>
      <!-- canUseButtons && gameLoaded && tempAccess -->
      <div class="menu__element-wrapper" v-if="false">
        <button class="menu__element weather" @click="openWeather = !openWeather" id="weatherTrigger">
          <img src="@/assets/icons/weather/weather.svg" v-if="!openWeather" alt="" class="menu__icon"/>
          <img src="@/assets/icons/weather/weather-active.svg" v-if="openWeather" alt="" class="menu__icon"/>
        </button>
        <div class="modal modal__weather" :class="{ 'modal__open': openWeather }" ref="weather">
          <div class="weather-list">
            <div class="menu__element-wrapper" v-for="weather in weatherList" :key="weather.id">
              <button class="weather-element" @click="sendWeather(weather)">
                <img :src="weather.weatherUrl" alt="emoji" class="weather-icon">
              </button>
            </div>
          </div>
        </div>
      </div>
      <button class="link-default" v-if="isRobotGuide" @click="disableRobotGuide">Отключить гид</button>
      <!-- TODO Счёт -->
      <div class="score" v-if="false">
        <img src="@/assets/new-icons/score.svg" alt="" class="score__img">
        <p class="score__text">120</p>
      </div>
    </div>
    <div class="header-filling header-right">
      <div v-tooltip="tooltips.WIFI" class="menu__element-wrapper">
        <div class="menu__element wifi">
          <div id="connection" class="tooltip">
            <svg version="1.1" id="connectionStrength" xmlns="http://www.w3.org/2000/svg"
                 xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 494.45 494.45"
                 style="enable-background: new 0 0 494.45 494.45" xml:space="preserve">
              <circle id="dot" cx="247.125" cy="398.925" r="35.3"/>

              <path id="middle"
                    d="M395.225,277.325c-6.8,0-13.5-2.6-18.7-7.8c-71.4-71.3-187.4-71.3-258.8,0c-10.3,10.3-27.1,10.3-37.4,0
                            s-10.3-27.1,0-37.4c92-92,241.6-92,333.6,0c10.3,10.3,10.3,27.1,0,37.4C408.725,274.725,401.925,277.325,395.225,277.325z"/>

              <path id="outer" d="M467.925,204.625c-6.8,0-13.5-2.6-18.7-7.8c-111.5-111.4-292.7-111.4-404.1,0c-10.3,10.3-27.1,10.3-37.4,0
                            s-10.3-27.1,0-37.4c64-64,149-99.2,239.5-99.2s175.5,35.2,239.5,99.2c10.3,10.3,10.3,27.1,0,37.4
                            C481.425,202.025,474.625,204.625,467.925,204.625z"/>

              <path id="inner" d="M323.625,348.825c-6.8,0-13.5-2.6-18.7-7.8c-15.4-15.4-36-23.9-57.8-23.9s-42.4,8.5-57.8,23.9
                            c-10.3,10.3-27.1,10.3-37.4,0c-10.3-10.3-10.3-27.1,0-37.4c25.4-25.4,59.2-39.4,95.2-39.4s69.8,14,95.2,39.5
                            c10.3,10.3,10.3,27.1,0,37.4C337.225,346.225,330.425,348.825,323.625,348.825z"/>
            </svg>
            <span class="tooltiptext" id="qualityText">Not connected</span>
          </div>
        </div>
      </div>
      <div v-tooltip="tooltips.EXIT" class="menu__element-wrapper">
        <router-link :to="{ name: 'mainPage' }" class="menu__element settings">
          <img src="@/assets/icons/exit.svg" alt="burger" class="menu__icon desktop"/>
          <img src="@/assets/icons/exit.svg" alt="burger" class="menu__icon mobile"/>
        </router-link>
      </div>
      <div class="menu__element-wrapper mobile" @click="openChat">
        <button class="menu__element chat ">
          <img src="@/assets/icons/open-chat.svg" alt="burger" class="menu__icon"/>
        </button>
      </div>
    </div>

  </header>
</template>

<script>
import { appConfig } from "@/lib/appConfig";
import { tooltips } from "@/lib/tooltips";
import { onClickOutside } from "@vueuse/core";
import { computed, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "GameHeader",
  props: ["viewChat", "trainingStep", "turnOffMicrophone", "myMediaStream", "userCall", "role", 'canUseButtons', 'gameLoaded', 'isRobotGuide'],
  setup(props, {emit}) {
    const weatherList = [
      {
        weatherUrl: require("@/assets/icons/weather/Sun.svg"),
        type: 'DayTime',
        value: '06'
      },
      {
        weatherUrl: require("@/assets/icons/weather/tabler_beach.svg"),
        type: 'Season',
        value: 'Summer'
      },
      {
        weatherUrl: require("@/assets/icons/weather/Cloud.svg"),
        type: 'DayTime',
        value: '12'
      },
      {
        weatherUrl: require("@/assets/icons/weather/autumn.svg"),
        type: 'Season',
        value: 'Autumn'
      },
      {
        weatherUrl: require("@/assets/icons/weather/Sunrise.svg"),
        type: 'DayTime',
        value: '18'
      },
      {
        weatherUrl: require("@/assets/icons/weather/spring.svg"),
        type: 'Season',
        value: 'Spring'
      },
      {
        weatherUrl: require("@/assets/icons/weather/Moon.svg"),
        type: 'DayTime',
        value: '00'
      },
    ];

    const weather = ref(null);

    const tempAccess = computed(() => {
      const store = useStore();
      const user = store?.getters?.userData;

      return appConfig.appState.mode === 'prod' ? (user?.email === 'svetlana.lobanova@mail.ru' || user?.email === 'amirov.and@ya.ru') : true
    })

    const openWeather = ref(false);

    const sendWeather = (weather) => {
      document.dispatchEvent(new CustomEvent(weather.type, {detail: weather.value}))
    }


    const changeMicrophone = () => {
      emit('changeTurnOffMicrophone');
      props.myMediaStream?.dispatchEvent(new CustomEvent('changeMicroStatus', {detail: props.turnOffMicrophone}))
    }
    const fullScreen = () => {
      document.fullscreenElement ? document.exitFullscreen() : document.getElementById('app').requestFullscreen();
    }

    onClickOutside(weather, ({target}) => {
      if(document.getElementById('weatherTrigger')?.contains(target)) return;
      openWeather.value = false;
    })


    return {
      openChat: () => emit("openChat"),
      // openMap: () => emit("openMap"),

      turnOffCamera: ref(false),
      turnOffSound: ref(false),

      nextStep: () => emit("nextStep"),
      prevStep: () => emit("prevStep"),
      closeTraining: () => emit("closeTraining"),
      disableRobotGuide: () => {
        document.dispatchEvent(new Event('DestroyRoboGuide'))
        emit("robotGuideDisable")
      },

      changeMicrophone,
      fullScreen,
      openWeather,
      weatherList,
      sendWeather,
      tempAccess, weather,
      tooltips,
    };
  },
};
</script>

<style scoped>
.header {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
  padding: 1rem;
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
}

.header-filling {
  position: relative;
  z-index: 2;
  display: flex;
  gap: 1rem;
  transition: all 1s ease-in-out;
  align-items: center;
  pointer-events: all;
}

.header-left {
  pointer-events: all;
  display: flex;
  gap: 1rem;
  align-items: center;
}


.menu__element {
  width: 100%;
  height: 100%;
  position: relative;
}
.menu__element.wifi,
.menu__element.settings,
.menu__element.full {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #003099;
    z-index: -1;
    border-radius: 50%;
  }
}

.wifi {
  background: #ffffff4d;
  border-radius: 100%;
  padding: 0.6rem;
}


.header-menu-open {
  border-radius: 80px 80px 0 80px;
}

.mobile {
  display: none;
}

.menu__element-wrapper {
  position: relative;
  height: 4rem;
  width: 4rem;
  background: rgba(157, 196, 248, 0.30);
  backdrop-filter: blur(4px);
  padding: 0.5rem;
  border-radius: 100%;
  display: flex;
  flex-shrink: 0;
}

.modal {
  position: absolute;
  transform: translateY(10%);
  opacity: 0;
  transition: all 0.5s ease-in-out;
  z-index: 3;
  pointer-events: none;
}

.modal__open {
  transform: translateY(0%);
  opacity: 1;
  pointer-events: all;
}

.modal__weather {
  top: 5rem;
  left: 0;
  width: min-content;
  border-radius: 15px;
}

.weather-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.75rem;
}

.weather-element {
  width: 3rem;
  height: 3rem;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background: #ffffff4d;
  border-radius: 100%;
}

.weather-icon {
  width: 1.75rem;
  height: 1.75rem;
}

.score {
  display: flex;
  align-items: center;
  gap: .375rem;
  padding: 1rem 1rem;
  border-radius: 1.5rem;
  backdrop-filter: blur(40px);
  background: rgba(236, 242, 250, 0.3);
}

.link-default {
  width: unset;
  line-height: 1;
}

.score__text {
  font-weight: 500;
  font-size: 1rem;
  line-height: 125%;
  color: #fff;
}

.link__img {
  width: 1.5rem;
  height: 1.5rem;
}

@media (max-width: 1000px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: flex;
  }

  .menu__element-wrapper {
    padding: 0.25rem;
    width: 2.5rem;
    height: 2.5rem;
  }

  .header-filling {
    gap: 0.5rem;
    padding: 0;
  }

  .menu__element {
    width: 100%;
    height: 100%;
  }

  .wifi {
    width: 100%;
    height: 100%;
    padding: 0.5rem;
  }

  .header {
    transition: all 1s ease-in-out;
  }

  .header-with-open-chat {
    right: 420px;
    transition: all 1s ease-in-out;
  }

  .header-left {
    gap: 0.5rem;
  }

  .modal__weather {
    top: 3rem;

  }

  .modal__weather {
    gap: 0.5rem;
  }

  .weather-element {
    width: 2rem;
    height: 2rem;
  }

  .weather-element img {
    width: 0.875rem;
  }

  .link-default {
    padding: 0.5rem;
    font-size: 0.75rem;
  }
}
</style>
