<template>
  <div class="training management" v-if="trainingStep === 0" ref="training">
    <div class="training-filling">
      <div class="training-caption ">
        <p class="title">Управление </p>
        <button class="close" @click="$emit('closeTraining')"></button>
      </div>
      <div class="filling filling--first">
        <img src="@/assets/new-icons/game/wasd.webp" alt="" class="block-img desktop">
        <img src="@/assets/new-icons/game/space.webp" alt="" class="block-img desktop">
        <img src="@/assets/new-icons/game/360.webp" alt="" class="block-img desktop">
        <img src="@/assets/new-icons/game/esc.webp" alt="" class="block-img desktop">
        <div class="block block__mobile ">
          <img src="@/assets/icons/game/training/mobile-move.png" alt="" class="block-img">
          <p class="block-text">Для перемещения пользователя по сцене
            и поворота камеры нажмите
            на экран и потяните виртуальные контроллеры</p>
        </div>
      </div>
      <div class="buttons buttons__horizontally">
        <button class="link-default" @click="handleClick">
          Создать виртуального аватара
          <img src="@/assets/new-icons/ArrowRight.svg" alt="перейти" class="arrow">
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { onClickOutside } from "@vueuse/core";
import { ref } from "vue";

export default {
  name: "RedesignTrainingComponent",
  props: ['trainingStep'],

  setup(props, {emit}) {

    const training = ref(null);
    const closeTraining = () => emit('closeTraining');
    const changeAvatarConstructState = () => emit('changeAvatarConstructState');

    const handleClick = () => {
      changeAvatarConstructState();
      closeTraining();
    };

    onClickOutside(training, closeTraining);

    return {
      closeTraining,
      changeAvatarConstructState,
      handleClick,
      training
    };
  }
};
</script>

<style scoped>
.training {
  font-family: "GolosText";
  max-width: 70.25rem;
  width: 100%;
  padding: 1.5rem;
  border-radius: 2.5rem;
  backdrop-filter: blur(8px);
  background: rgba(157, 196, 248, 0.3);
  margin: auto;
  position: absolute;
  pointer-events: all;
  z-index: 1000000;
}

.training-filling {
  padding: 1.5rem;
  border-radius: 1.5rem;
  backdrop-filter: blur(80px);
  background: rgba(229, 229, 229, 0.2);
  max-width: 100%;
  width: 100%;
}

.training-caption {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-weight: 500;
  font-size: 2rem;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: #fff;
}


.buttons {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
}


.link-default {
  pointer-events: all;
  background: #003099;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0.03em;
  text-align: center;
  color: #fff;
  padding: 0.5rem 0.75rem;
  width: unset;
  gap: 0.75rem;
}

.link-default__disabled {
  background: #ecf2fa;
  color: #8b98aa;
}


.filling {
  margin-top: 2.0625rem;
  display: flex;
  gap: 1.31rem;
}

.filling--first {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.75rem;
}

.block {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.block-img {
  pointer-events: none;
}

.block-text {
  color: #fff;
  text-align: left;
  font-size: 0.88rem;
  line-height: 143%;
}

.block-text span {
  color: #003099;
  font-weight: 700;

}

.block-text:deep(span) {
  color: #003099;
  font-weight: 700;

}

.content {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}


.close {
  background: url("@/assets/new-icons/close-defoalt.svg");
  width: 2rem;
  height: 2rem;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  right: 1.5rem;
}

.mobile {
  display: none;
}

.block__mobile {
  display: none;
}

@media (max-width: 1000px) {
  .mobile {
    display: flex;
  }

  .training {
    max-width: 28rem;
    padding: 0.62rem;
    border-radius: 1.5rem;
  }

  .training-filling {
    padding: 1rem;
  }


  .title {
    font-size: 1rem;
  }


  .buttons {
    flex-direction: row;
    gap: 0.54rem;
  }

  .filling {
    margin: 1.5rem 0;
  }

  .link-default {
    padding: 0.63rem;
    font-size: 0.75rem;
    line-height: normal;
    border-radius: 0.5rem;
  }

  .block__mobile {
    display: flex !important;
    flex-direction: row;
    align-items: center;
  }

  .management .block-img {
    max-width: 12.0625rem;
  }

  .filling--first {
    display: flex;
  }

  .interaction .block-img {
    max-width: 7.4375rem;
  }

  .arrow {
    width: 1rem;
    height: 1rem;
  }

  .close {
    width: 1.5rem;
    height: 1.5rem;
  }

  .block-text {
    font-size: 0.75rem;
    line-height: 0.875rem;
    text-align: left;
  }

  .desktop {
    display: none;
  }
}
</style>
