export const tooltips = {
  FULL_SCREEN: 'Полноэкранный режим',
  WIFI: 'Уровень соединения с сетью',
  EXIT: 'Выход из локации',
  CHAT: 'Чат',
  AVATAR_EDIT: 'Редактор аватара',
  TOGGLE_SOUND: 'Вкл/выкл звук',
  VIEW: 'Переключение вида камеры',
  SCOOTER: ' Активация режима передвижения на самокате',
  MAP: 'Карта локации',
  PAVILION_MAP: 'Схема павильона',
}