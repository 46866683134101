<script setup>
import { defineProps, computed, shallowRef, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { Vue3Lottie } from 'vue3-lottie'
import loaderJSON from '@/assets/loader.json';


const props = defineProps({
  page: {
    type: Boolean,
    default: true,
  }
});

const route = useRoute();

const loaderClasses = computed(() => ['loader-wrapper', {
  page: props.page
}])

const isVanya = shallowRef(false);

onMounted(() => {
  if (route.query.place === 'russia') {
    return;
  }

  if (sessionStorage.getItem('showInfo') === '1') {
    console.log('showInfo');
    isVanya.value = sessionStorage.getItem('isVanya') === 'true';
    sessionStorage.removeItem('showInfo');
  }
});
</script>

<template>
  <div :class="loaderClasses">
    <div class="loader">
      <div class="loader-filling">
        <Vue3Lottie :animationData="loaderJSON" :height="200" :width="200" />
        <p v-if="isVanya && page" class="loader-text">
          МетаВДНХ. «Виртуальная экскурсия с Ваней Дмитриенко» является информационно-развлекательным проектом и не претендует на полную историческую достоверность.
        </p>
      </div>
    </div>
  </div>
</template>


<style lang="scss" scoped>
.loader-wrapper {

  position: relative;

  &.page {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    z-index: 10000000;
  }

  background: #ecf2fa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-filling {
  display: flex;
  flex-direction: column;
  align-items: center;
}



.icon {
  width: 204px;
  height: 204px;
}

.loader-text {
  position: absolute;
  max-width: 1280px;
  padding: 0 30px;
  width: 100%;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  margin-top: auto;
  color: #003099;
  font-size: 15px;
  letter-spacing: -0.24px;
  text-align: center;
}

.clue {
  position: absolute;
  bottom: 141px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 16px 24px 16px 112px;
  border-radius: 8px;
  background: #fff;
}

.clue-icon {
  position: absolute;
  width: 80px;
  height: 80px;
  left: 16px;
}

.clue-text {
  color: #000;
  font-size: 16px;
  letter-spacing: 0.48px;
}

.clue-text span {
  color: #abb4c0;
}

.footer {
  position: fixed;
  bottom: 24px;
  padding: 0 48px;
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  width: 100%;
}

.footer-info {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.14px;
  color: #8b98aa;
}

.footer-politics {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.14px;
  text-align: right;
  color: #8b98aa;
}

.footer-politics a {
  color: #003099;
}

@media (max-height: 686px) {
  .icon {
    width: 120px;
    height: 120px;
  }

  .loader-text {
    margin-top: 12px;
    font-size: 16px;
  }

  .clue {
    bottom: unset;
    top: 30px;
    padding: 10px 16px 10px 80px;
  }

  .clue-icon {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 16px;
  }

  .clue-text {
    color: #000;
    font-size: 14px;
    letter-spacing: 0.48px;
  }

  .clue-text span {
    color: #abb4c0;
  }

  .footer {
    bottom: 20px;
    padding: 0 24px;
    gap: 20px;
  }

  .footer-info {
    font-size: 12px;
  }

  .footer-politics {
    font-size: 12px;
  }

  .footer-politics a {
    color: #003099;
  }
}
</style>
