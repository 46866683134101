<script setup>
import { defineProps, defineEmits, computed } from "vue";
import dayjs from "dayjs";

const MOSCOW = 'Europe/Moscow';

const props = defineProps({
  dateString: {
    type: String,
    required: true,
  },
  modelValue: {
    type: String,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
});

const emits = defineEmits(["update:modelValue"]);

const date = computed(() => dayjs(props.dateString).tz(MOSCOW));
const dayShortName = computed(() => date.value.format('dd'));
const day = computed(() => date.value.toDate().getDate());
const monthLongName = computed(() => date.value.toDate().toLocaleDateString('ru-RU', { month: 'long' }));

function onChange({ target }) {
  if (!target.value) {
    return;
  }

  emits("update:modelValue", target.value);
}
</script>

<template>
  <label>
    <input :checked="index === modelValue" type="radio" class="dates-element__input" name="date" :value="index" @change="onChange" />
    <span class="dates-element__day">{{ dayShortName }}</span>
    <span class="dates-element__date title-h1 content-center">{{ day }}</span>
    <span class="dates-element__month">{{ monthLongName }}</span>
    <span class="dates-element__bg"></span>
  </label>
</template>

<style scoped lang="scss">
.title {
  &-h1 {
    font-weight: 500;
    font-size: 2rem;
    line-height: 110%;
    color: #13151a;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      font-size: 1.75rem;
    }
  }

  &-h2 {
    font-weight: 500;
    font-size: 1.625rem;
    line-height: 125%;
    color: #13151a;

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }
}

.caption {
  display: flex;
  align-items: center;
  gap: 1.5rem;

  &--mob {
    display: none;
  }

  @media (max-width: 1100px) {
    gap: 0.75rem;

    &--desk {
      display: none;
    }

    &--mob {
      display: flex;
    }

    &__title {
      font-size: 1rem;
      line-height: 140%;
      color: #828282;
    }
  }
}

.dates {
  &-element {
    cursor: pointer;
    &__bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border: 1px solid #f5f6f7;
      border-radius: 1rem;
      z-index: 1;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
    }

    label {
      cursor: pointer;
    }

    &__input {
      margin-top: 0;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;

      &:disabled~.dates-element__bg {
        background: rgba(255, 255, 255, 0.64);
      }

      &:checked~.dates-element__bg {
        background: #00288c;
      }

      &:checked~.dates-element__day {
        color: rgba(255, 255, 255, 0.64);
      }

      &:checked~.dates-element__date {
        color: #fff;
      }

      &:checked~.dates-element__month {
        color: rgba(255, 255, 255, 0.64);
      }
    }

    &__day {
      top: 0.5rem;
      right: 0.5rem;
      position: absolute;
      z-index: 2;
      transition: all 0.2s ease-in-out;
      font-weight: 500;
      font-size: 0.625rem;
      line-height: 140%;
      color: #666;
    }

    &__date {
      position: relative;
      z-index: 2;
      transition: all 0.2s ease-in-out;
    }

    &__month {
      margin-top: 0.25rem;
      position: relative;
      z-index: 2;
      transition: all 0.2s ease-in-out;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 100%;
      color: #666;
    }
  }
}
</style>