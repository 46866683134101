<template>
  <div class="map-wrapper" ref="mapWrapper">
    <div class="map-content">
      <div class="map">
        <div class="map-info">
          <p class="map-info-title">Выберите помещение</p>
          <label class="search">
            <img src="@/assets/icons/search.svg" alt="" class="search-icon">
            <input v-model="searchName" type="text" class="search-input" placeholder="Поиск по помещениям">
          </label>
          <div class="map-list">
            <!-- <Component :is="mapCardComponent"
              v-for="(item, index) in currentMapElements?.filter(mapEl => mapEl?.id && (searchName ? (mapEl.name.toLowerCase().includes(searchName.toLowerCase())) : true))"
              :is-map="true" :is-russian="isRussia" :is-landing="landing" :current-object="currentObject"
              :map-element="item" :locale="locale" @openObjectInfo="openObjectInfo" :key="index" :index="index"
              @openCurrentObject="openCurrentObject" @closeMap="closeMap" /> -->
            <MapElementMedium
              v-for="(item, index) in currentLevel.elements?.filter(mapEl => mapEl?.id && (searchName ? (mapEl.name.toLowerCase().includes(searchName.toLowerCase())) : true))"
              :is-map="true" :is-landing="landing" :current-object="currentObject" :map-element="item" :locale="locale"
              @openObjectInfo="openObjectInfo" :key="index" :index="index" @openCurrentObject="openCurrentObject"
              @closeMap="closeMap" />
          </div>
        </div>
        <div class="map-fill" :class="currentLevel.class">
          <div class="map-caption">
            <div class="map-caption__info">
              <p class="map__name" v-html="currentLevel.title" />
              <p class="map__subtitle">Выберите на карте интересующий вас зал, вся информация о нем доступна слева.
                <br /> А для включения аудиогида нажмите клавишу «Е» на клавиатуре.
              </p>
            </div>
            <!-- TODO передавай logo если есть -->
            <img src="@/assets/pavilions/cibur-logo.svg" alt="" class="map__logo" v-if="currentLevel.logo">
          </div>
          <!-- TODO -->
          <p class="map__subtitle m-t-0" v-if="false">В Метавселенной ВДНХ у вас есть возможность посетить шесть
            павильонов
            международной выставки-форума «Россия». Оцифрованные объекты подсвечиваются синим при наведении на них
            курсора. Изучите, каким достижениям страны посвящена каждая экспозиция</p>
          <div class="map-container map-desktop" id="map-filling">
            <NewMapElementInfo v-if="currentObject" :isGame="true" :locale="locale" :current-object="currentObject"
              @robotGuideEnable="robotGuideEnable" @closeInformation="currentObject = null" @closeMap="closeMap" />
            <PavilionMapDraggable :current-object="currentObject" :pavilionElements="currentLevel.elements" ref="map"
              :map-img="currentLevel.mapImg" :locale="locale" :map-scale="mapScale"
              @openCurrentObject="openCurrentObject" @closeMap="closeMap" />
            <div class="map-management">
              <div class="zoom">
                <button class="management__element zoom-plus" @click="zoom(true)"></button>
                <button class="management__element zoom-minus" @click="zoom(false)"></button>
              </div>
              <button class="management__element position" v-if="false"></button>
              <button class="management__element size" @click="mapFullScreen"></button>
            </div>
          </div>
        </div>

        <button class="map__close" @click="closeMap">
          <img src="@/assets/icons/close-main.svg" alt="" class="close-img" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import MapElementMedium from "@/components/map/MapElementMedium.vue";
import NewMapElementInfo from "@/components/map/NewMapElementInfo.vue";
import PavilionMapDraggable from "@/components/pavilion-map/PavilionMapDraggable";
import pavilionElements from "@/lib/pavilionElements.js";
import { russiaMapNames } from "@/lib/russia";
import { onClickOutside } from "@vueuse/core";
import { computed, defineEmits, defineProps, ref } from "vue";
import { useStore } from "vuex";

defineProps(['locale', 'landing']);
const emits = defineEmits(['closeMap', 'openObjectInfo', 'robotGuideEnable']);


const store = useStore();
const currentLevelName = computed(() => store.state.application.currentLevelName?.trim());

const currentLevel = computed(() => {
  const index = russiaMapNames.findIndex((name) => name === currentLevelName.value);
  return pavilionElements?.[index];
});

const map = ref(null);
const mapWrapper = ref(null);

const searchName = ref(null);

const currentObject = ref(null);
const mapScale = ref(1);

onClickOutside(mapWrapper, closeMap);

function zoom(isPlus) {
  if (isPlus) {
    if (mapScale.value === 2) return;
    mapScale.value += 0.5;
  } else {
    if (mapScale.value === 0.5) return;
    mapScale.value -= 0.5;
  }
}

function closeMap() {
  emits("closeMap")
}

function mapFullScreen() {
  document.fullscreenElement ? document.exitFullscreen() : document.getElementById('map-filling').requestFullscreen();
}

function openCurrentObject(val) {
  if (currentObject?.value?.id === val?.id) {
    currentObject.value = null;
    return
  }
  currentObject.value = val;
}

function openObjectInfo(val) {
  emits('openObjectInfo', { val, openNavigation: true });
}

function robotGuideEnable() {
  emits('robotGuideEnable');
  closeMap();
}
</script>

<style scoped>
* {
  box-sizing: border-box;
  font-family: "GolosText";
}

.map-wrapper {
  padding: 0 1rem;
  height: calc(100% - 12rem);
  position: absolute;
  z-index: 3;
  max-width: 100%;
  width: 100%;
  display: flex;

}

.map-content {
  padding: 0.63rem;
  max-width: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 0.5rem;
  background: rgba(157, 196, 248, 0.30);
  backdrop-filter: blur(4px);
  pointer-events: all;
}

.map {
  height: 100%;
  max-width: 100%;
  width: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
  background: #FFF;
  display: flex;
  gap: 1rem;
}

.map-info {
  max-width: 23.875rem;
  width: 100%;
  padding: 1.5rem 1rem;
  border-radius: 1rem;
  background: #ECF2FA;
  display: flex;
  flex-direction: column;
}

.map-info-title {
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.00875rem;
}

.search {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.69rem 1rem;
  border-radius: 0.5rem;
  background: #FFF;
}

.search-icon {
  display: flex;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.search-input {
  margin-top: 0;
  padding: 0;
  background: none;
  border-radius: 0;
  border: none;
  color: #ABB4C0;
  font-size: 1rem;
}

.map-container {
  display: flex;
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.map-fill {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 100%;
  width: 100%;
}

.map-caption {
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  z-index: 1;
  pointer-events: none;

}

.map__close {
  display: flex;
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
}

.close-img {
  width: 100%;
  height: 100%;
}

.map-list {
  margin-top: 1.5rem;
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;
  overflow: auto;
}

.map-list:deep(.element-demonstration) {
  height: 126px;
}

.map-container {
  padding: 2rem;
  border-radius: 1rem;
  background: #ECF2FA;
  display: flex;
  max-width: 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  align-items: center;
}

.map-management {
  position: absolute;
  right: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
}

.management__element {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
}

.zoom {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.zoom-plus {
  background: url("@/assets/icons/game/zoom-plus.svg") no-repeat center;
  background-size: cover;
}

.zoom-minus {
  background: url("@/assets/icons/game/zoom-minus.svg") no-repeat center;
  background-size: cover;
}

.position {
  background: url("@/assets/icons/game/pozition.svg");
}

.size {
  background: url("@/assets/icons/game/resize.svg") no-repeat center;
  background-size: cover;
  width: 3rem;
  height: 3rem;
}



.map__name {
  font-weight: 700;
  font-size: 24px;
  letter-spacing: -0.01em;
  color: #000;
}

.map__subtitle {
  margin-top: 4px;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: -0.01em;
  color: #6f6f6f;
}

.m-t-0 {
  margin-top: 0;
}

.map__logo {
  width: 87px;
}


@media (max-width: 1400px) {
  .management__element {
    width: 2rem;
    height: 2rem;
  }

  .size {
    width: 2rem;
    height: 2rem;
  }


  .cibur-fill:deep(.map-content) {
    scale: 0.6;
  }

  .russia-fill:deep(.map-content) {
    scale: 0.6;
  }

  .sber-fill:deep(.map-content) {
    scale: 0.8;
  }

  .town-fill:deep(.map-content) {
    scale: 1;
  }

  .town-fill:deep(.web-fill) {
    scale: 1;
  }

  .finance-fill:deep(.map-content) {
    scale: 0.6;
  }
}

@media (max-width: 1000px) {
  .map-wrapper {
    padding: 0 3rem;
    height: calc(100% - 8rem);

  }

  .map-list:deep(.element-demonstration) {
    width: 100%;
    height: 74px;
  }

  .map-content {
    padding: 0.37rem;
    border-radius: 0.5rem;
  }

  .map {
    padding: 0.5rem;
    border-radius: 0.5rem;
    gap: 0.5rem;
  }

  .map-info {
    padding: 0.5rem;
    border-radius: 0.5rem;
  }

  .map-info-title {
    font-size: 0.75rem;
  }

  .map__close {
    width: 1.5rem;
    height: 1.5rem;
  }

  .map-info {
    max-width: 14rem;
  }

  .search {
    margin-top: 0.5rem;
    padding: 0.5rem;
    gap: 0.5rem;
  }

  .search-icon {
    width: 1rem;
    height: 1rem;
  }

  .map-list {
    margin-top: 0.5rem;
  }
}
</style>
