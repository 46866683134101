<script setup>
import { defineProps, defineEmits, computed } from 'vue';

const props = defineProps({
  title: {
    default: '',
    type: String,
  },
  value: {
    required: true,
    type: Number,
  },
  disabled: {
    default: false,
    type: Boolean,
  },
  modelValue: {
    type: Number,
  },
  item: {
    type: Object,
    required: true,
  }
});

const time = computed(() => {
  return new Date(props.item.startTime).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
});

const emits = defineEmits({
  'update:modelValue': (...args) => args,
  'slot': (...args) => args,
});

const model = computed({
  get: () => props.modelValue,
  set: (value) => emits('update:modelValue', value)
});

const onTimeSlotClick = () => {
  if (props.disabled) {
    if (sessionStorage.getItem('slotId')) {
      sessionStorage.removeItem('slotId');
    }

    emits('slot', props.item);
    model.value = null;

    return;
  }

  sessionStorage.setItem('slotId', props.item.id);
  emits('slot', null);
}

const slotsElementClasses = computed(() => [
  'slots-element',
  {
    'slots-element--selected': props.disabled,
  }
])
</script>

<template>
  <div :class="slotsElementClasses" @click="onTimeSlotClick">
    <label class="slots-element__content" :class="{ disabled: false }">
      <input v-model="model" :title="title" :disabled="disabled" type="radio" class="slots-element__input" name="time"
        :value="value">
      <span class="slots-element__bg"></span>
      <span class="slots-element__text">{{ time }}</span>
    </label>
    <p class="slots-element__number">{{ item.count }} шт.</p>
  </div>
</template>

<style lang="scss" scoped>
.slots {

  &-element {
    text-align: center;


    @media (hover: hover) {
      &:hover {
        .slots-element__bg {
          background: #A5C3F0;
        }

        .slots-element__text {
          color: #00288C;
        }
      }
    }

    &--selected {
      .slots-element__bg {
        background: #00288c;
      }

      .slots-element__text {
        color: #fff;
      }

      @media (hover: hover) {
        &:hover {
            .slots-element__bg {
              background: #A5C3F0;
            }

            .slots-element__text {
              color: #000;
            }
          }
      }
    }

    @media (max-width: 768px) {
      max-width: 100%;
      width: 100%;
    }

    &__content {
      display: flex;
      cursor: pointer;
      padding: 0.75rem;
      border-radius: 0.25rem;
      position: relative;
      justify-content: center;

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      @media (max-width: 768px) {
        max-width: 100%;
        width: 100%;
      }

    }

    &__text {
      font-size: 0.875rem;
      line-height: 100%;
      text-align: center;
      color: #13151a;
      position: relative;
      z-index: 2;
      transition: all 1s ease;
    }

    &__input {
      margin-top: 0;
      position: absolute;
      opacity: 0;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      &:checked~.slots-element__bg {
        background: #A5C3F0 !important;
      }

      &:checked~.slots-element__text {
        color: #00288C !important;
      }

      @media (max-width: 768px) {
        &:checked~.slots-element__bg {
          background: #A5C3F0 !important;
        }

        &:checked~.slots-element__text {
          color: #00288C !important;
        }
      }
    }

    &__bg {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      border: 1px solid #f5f6f7;
      border-radius: 0.25rem;
      background: #f5f6f7;
      transition: all 1s ease;
    }

    &__number {
      margin-top: 0.5rem;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 110%;
      color: #666;
    }
  }
}
</style>
