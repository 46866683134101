export default [
  {
    "name": "СЕМЬЯ",
    "category": "rf",
    "description": "Экспонат в интересных фактах рассказывает о том, как изменилась жизнь российской семьи в городах за прошедшие 20 лет. Если раньше первый ребенок появлялся в семье к 24-26 годам, то сегодня пара получает высшее образование и начинает карьеру, а рождение первенца происходит к 29 годам. Отпуск на даче с грядками сменил отдых на черноморских курортах, а проживание с родителями в одной квартире – покупка собственного жилья или аренда недвижимости. ",
    "imgUrl": require("@/assets/pavilions/webrf/d7528e242e12bea964822e7a0032c423.png"),
    "Id": "d7528e242e12bea964822e7a0032c423",
  },
  {
    "name": "Кампусы",
    "category": "rf",
    "description": "Экспонат рассказывает, как современные технологичные кампусы в вузах меняют формат получения высшего образования в стране, формируют комфортную и развитую инфраструктуру, чтобы сделать процесс обучения еще более эффективным. Кампусы не просто объединяют лучшие вузы региона на одной площадке: здесь есть библиотеки и аудитории для лекций, комфортные общежития и комьюнити единомышленников. Кроме знаний студенты смогут получить поддержку для реализации своих проектов от компаний и фондов. ",
    "imgUrl": require("@/assets/pavilions/webrf/3e6014d12857f09a08b555b9aa59e73e.png"),
    "Id": "3e6014d12857f09a08b555b9aa59e73e"
  },
  {
    "name": "Школьный город",
    "category": "rf",
    "description": "Экспонат демонстрирует, как сегодня меняется современная школа и как технологии делают процесс обучения интерактивным и персонализированным. В начале 2000-х улучшение образовательной системы стало одним из национальных приоритетов. В новых школах делается акцент на раскрытие потенциала и развитие талантов каждого ученика. В них предусмотрены бассейны, IT-центры, кинотеатры, залы хореографии и даже студии звукозаписи. В инженерных классах школьников готовят к поступлению в профильные университеты, а занятия проходят в интерактивном формате с использованием технологичного оборудования и цифровых технологий.  ",
    "imgUrl": require("@/assets/pavilions/webrf/d2ea754c6b10bf3628fdb1e065ef9736.png"),
    "Id": "d2ea754c6b10bf3628fdb1e065ef9736"
  },
  {
    "name": "Умный город",
    "category": "rf",
    "description": "Экспонат «Умный город» рассказывает, как развитие технологий в городах делает жизнь каждого жителя удобнее. В России уже насчитывается 3 миллиона умных домов, а в 2025 их число может вырасти почти втрое. В таких домах технологии помогают решать бытовые вопросы, а люди могут больше времени проводить вместе, ощущая полную безопасность и комфорт. Уже сегодня система «Умный дом» помогает управлять розетками, настраивать яркость света, запускать умные пылесосы, чайники и стиральные машины, регулировать микроклимат, удаленно открывать двери и ворота, контролировать протечки и многое другое. ",
    "imgUrl": require("@/assets/pavilions/webrf/7c91f66ff1baa10f954c26c9123d6aab.png"),
    "Id": "7c91f66ff1baa10f954c26c9123d6aab"
  },
  {
    "name": "Здоровый город",
    "category": "rf",
    "description": "Экспонат «Здоровый город» посвящен развитию здравоохранения в городах России. Качественная медицина – залог долгой и счастливой жизни всех членов семьи, особенно старшего поколения. Это доказывает и статистика: за почти 30 лет средняя продолжительность жизни увеличилась более чем на восемь лет и стала составлять 72,8 года. Сегодня новые технологии в медицине позволяют быстрее проводить диагностику, проводить сложнейшие операции и создавать более эффективные лекарства.",
    "imgUrl": require("@/assets/pavilions/webrf/59401285a77c3efd124cdb0e08f586ba.png"),
    "Id": "59401285a77c3efd124cdb0e08f586ba"
  },
  {
    "name": "Как создаются лекарства?",
    "category": "rf",
    "description": "Благодаря технологиям и компьютерному моделированию разработка новых лекарств и клинические испытания идут еще быстрее. А сами лекарства стали доступнее и эффективнее. Создание любого лекарства начинается с предварительного исследования. Перед тем, как попасть на полки аптек или в медучреждения, оно должно пройти несколько этапов разработки и клинических испытаний. На этом стенде мы видим, какой путь проходит новое лекарство, у которого еще нет аналогов на рынке.",
    "imgUrl": require("@/assets/pavilions/webrf/aafbe64fc87d639503fbd6b9f3d56025.png"),
    "Id": "aafbe64fc87d639503fbd6b9f3d56025"
  },
  {
    "name": "Мой город самый-самый",
    "category": "rf",
    "description": "Для ВЭБ.РФ развитие городской экономики для повышения качества жизни россиян – один из приоритетов. При этом учитываются особенности разных городов и регионов, а также принимаются во внимание лучшие практики городского развития, ведь каждый из 1119 городов России – особенный. Здесь вы узнаете о самых-самых городах нашей страны: самом большом, холодном, высокогорном и других.",
    "imgUrl": require("@/assets/pavilions/webrf/9cdfdcc1bd0767a749086040692aeeae.png"),
    "Id": "9cdfdcc1bd0767a749086040692aeeae"
  },
  {
    "name": "Автомат перекоса",
    "category": "rf",
    "description": "Какие открытия совершила российская наука за последние 20 лет? Например, в 2017 году московским физиками из Российского квантового центра удалось запустить первый в мире квантовый блокчейн. Это система хранения данных, которую невозможно взломать, потому что она защищена методами квантовой криптографии. А еще российские ученые уже в новом тысячелетии продолжают дополнять таблицу Менделеева: с 2000 по 2010 год у физиков из Объединенного института ядерных исследований в Дубне получилось синтезировать шесть сверхтяжелых элементов. ",
    "imgUrl": require("@/assets/pavilions/webrf/0c420a563b5c7c58f7e134be0e96c9e0.png"),
    "Id": "0c420a563b5c7c58f7e134be0e96c9e0"
  },
  {
    "name": "Наукоград",
    "category": "rf",
    "description": "Достижения настоящего и будущего невозможны без открытий, сделанных в прошлом. История отечественной̆ науки полна открытий и изобретений, которые буквально переворачивали представление человека о мире. В зоне «Наукоград» вы узнаете, какие привычные нам технологии или их прообразы были созданы российскими учеными. Подробнее об этом расскажут виртуальные экспонаты Политехнического музея.	",
    "imgUrl": require("@/assets/pavilions/webrf/6cdb6475978be22f8c1bceac0cf89f9f.png"),
    "Id": "6cdb6475978be22f8c1bceac0cf89f9f"
  },
  {
    "name": "Чистый город",
    "category": "rf",
    "description": "Экспонат расскажет, как развитие экологических проектов способствует развитию и процветанию городов. Комфорт жизни семьи во многом обусловлен качеством экологии в городе. Эффективно управлять ресурсами сегодня помогают отечественные цифровые технологии, которые позволяют управлять городом и быстрее принимать важные решения: распределять ресурсы, следить за экологической ситуацией, справляться с утилизацией отходов, развивать экологичный транспорт. ",
    "imgUrl": require("@/assets/pavilions/webrf/6c85d8a8e333923fc56856a51829383d.png"),
    "Id": "6c85d8a8e333923fc56856a51829383d"
  },
  {
    "name": "Электрогефест",
    "category": "rf",
    "description": "Николай Бенардо́с изобрел электрическую дуговую сварку, которая легла в основу разработки современных сварочных аппаратов. Сегодня без электросварки немыслимо производство металлоконструкций для самых разных задач.",
    "imgUrl": require("@/assets/pavilions/webrf/e26873a4b6d62f5467efef5f887b063c.png"),
    "Id": "e26873a4b6d62f5467efef5f887b063c"
  },
  {
    "name": "Дуговая лампа Чиколева",
    "category": "rf",
    "description": "Экспонат демонстрирует электрическую дуговую лампу Владимира Чиколева, изобретенную в 1874 году. Некоторое время они были самыми распространенными источниками света в Германии и России. Тогда дуговые лампы работали не более 10 часов. Современные же лампочки могут прослужить до 50 тысяч часов. ",
    "imgUrl": require("@/assets/pavilions/webrf/0fdccafe5c074eeb44ae2b72ba975c2f.png"),
    "Id": "0fdccafe5c074eeb44ae2b72ba975c2f"
  },
  {
    "name": "Фонарь со свечой Яблочкова",
    "category": "rf",
    "description": "Один из первых в истории электрических источников света — «электрическую свечу» — создал Павел Яблочков. Благодаря этому изобретению искусственный свет стал использоваться повсеместно: на улицах, площадях, в театрах, магазинах и заводах.",
    "imgUrl": require("@/assets/pavilions/webrf/fb4d4db9d7b01ced23aaa4a3f1221c87.png"),
    "Id": "fb4d4db9d7b01ced23aaa4a3f1221c87"
  },
  {
    "name": "Электрическая лампа накаливания Лодыгина",
    "category": "rf",
    "description": "В XIX веке Александр Лодыгин первым предложил изготавливать нить накаливания из вольфрама. Так появилась одна из первых массовых ламп накаливания, которая постепенно заменила в жилых домах свечи и керосиновые лампы. А еще благодаря этому изобретению в 1873 году в Петербурге впервые зажглись уличные фонари. ",
    "imgUrl": require("@/assets/pavilions/webrf/ad8ae0d2b0924b5c1ef78d7c565afc8d.png"),
    "Id": "ad8ae0d2b0924b5c1ef78d7c565afc8d"
  },
  {
    "name": "Вертикальный сейсмограф Голицына",
    "category": "rf",
    "description": "В начале XX века Борис Голицын совершил революцию, представив сейсмограф весом около 10 килограммов с большей чувствительностью, чем другие аппараты того времени. Сейсмографы не только позволили ученым фиксировать землетрясения, но и помогли определить внутреннее строение Земли.",
    "imgUrl": require("@/assets/pavilions/webrf/78255c16ca4e736dd4dcd6c752eb1471.png"),
    "Id": "78255c16ca4e736dd4dcd6c752eb1471"
  },
]