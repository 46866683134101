<template>
  <div class="image-wrapper" ref="map">
    <div class="map__img">
      <map-element @openCurrentObject="openCurrentObject" @teleport="teleport" :current-object="currentObject"
        v-for="(item, index) in mapElements?.filter(mapEl => !mapEl?.hidden)" :index="index" :locale="locale"
        :map-element="item" :key="index">
      </map-element>
    </div>
    <div class="map-plug"></div>
  </div>
</template>

<script>
import MapElement from "@/components/map/MapElement.vue";
import interact from "interactjs";
import { onMounted, ref, watch } from "vue";

export default {
  name: "MapDraggableInGame",
  components: {
    MapElement
  },
  props: [
    'mapScale',
    'mapElements',
    'currentObject',
    'locale'
  ],
  emits: [
    'closeMap',
    'openCurrentObject',
  ],
  setup(props, { emit }) {
    const activeLocationId = ref(null);

    const screenX = ref(0);
    const screenY = ref(0);

    const map = ref();


    watch(() => props.mapScale, (val) => {
      map.value.style.scale = val;
    })

    const teleport = (id) => {
      document.dispatchEvent(new CustomEvent("Teleport", { detail: id }));
      emit('closeMap');
    };

    function dragMoveListener(event) {
      let target = event.target,
        // keep the dragged position in the data-x/data-y attributes
        x =
          (parseFloat(target.getAttribute("data-x")) || screenX.value) +
          event.dx,
        y =
          (parseFloat(target.getAttribute("data-y")) || screenY.value) +
          event.dy;

      // translate the element
      target.style.webkitTransform = target.style.transform =
        "translate(" + x + "px, " + y + "px)";

      // update the posiion attributes
      target.setAttribute("data-x", x);
      target.setAttribute("data-y", y);
    }


    function onDragEnd(event) {
      let target = event.target;
      // update the state
      screenX.value = target.getBoundingClientRect().left;
      screenY.value = target.getBoundingClientRect().top;
    }

    function initInteract(selector) {
      interact(selector).draggable({
        // enable inertial throwing
        inertia: true,
        // keep the element within the area of it's parent
        restrict: {
          restriction: "parent",
          endOnly: true,
        },
        // enable autoScroll
        autoScroll: true,

        // call this function on every dragmove event
        onmove: dragMoveListener,
        // call this function on every dragend event
        onend: onDragEnd,
      });
    }

    onMounted(() => {
      let myDraggable = map.value;
      initInteract(myDraggable);
    });
    return {
      teleport,
      map,
      activeLocationId,
      openCurrentObject(val) {
        emit('openCurrentObject', val);
      }
    }
  }
}
</script>

<style scoped>
.image-wrapper {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  transition: scale 1s ease-in-out;
}

.map__img {
  position: absolute;
  background-image: url("@/assets/icons/game/map.png");
  background-color: #ECF2FA;
  width: 104rem;
  height: 57rem;
  background-size: contain;
  background-position: center;
  z-index: 1;
}

.map-plug {
  position: absolute;
  width: 300vw;
  height: 300vh;
  left: -100vw;
  top: -100vh;
}


@media (max-width: 1500px) {
  .map__img {
    width: 78.1875rem;
    height: 42rem;
  }

}

@media (max-width: 1000px) {
  .map__img:deep(.place-btn) {
    display: flex;
  }

  .map__img:deep(.element) {
    display: none;

  }

}
</style>
