<script>
import MapDraggableInGame from "@/components/map/MapDraggableInGame";
import MapElementMedium from "@/components/map/MapElementMedium.vue";
import MapElementSmall from "@/components/map/MapElementSmall.vue";
import NewMapElementInfo from "@/components/map/NewMapElementInfo.vue";
import allMapElements from "@/lib/data.js";
import locations from "@/lib/locations.json";
import mapElements from "@/lib/mapElements.js";
import russiaMapElements from "@/lib/russia.js";
import { onClickOutside } from "@vueuse/core";
import { computed, ref } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "MapComponent",
  components: {
    NewMapElementInfo,
    MapDraggableInGame,
    MapElementSmall,
    MapElementMedium,
  },
  props: ["locale", "landing"],
  emits: ["closeMap", "openObjectInfo", "robotGuideEnable"],
  setup(props, { emit }) {
    const route = useRoute();
    const isRussia = computed(
      () =>
        route.query?.place?.trim().toLowerCase() === locations.russian?.place?.trim() ||
        props.landing
    );
    const map = ref(null);
    const mapWrapper = ref(null);
    const mapTitle = computed(() =>
      isRussia.value ? "Выберите павильон выставки" : "Локации ВДНХ"
    );

    const mapCardComponent = computed(() =>
      isRussia.value ? MapElementMedium : MapElementSmall
    );

    const specificMapElements = computed(() =>
      isRussia.value ? russiaMapElements : mapElements
    );

    const searchName = ref(null);

    const currentObject = ref(null);
    const closeMap = () => emit("closeMap");
    const mapScale = ref(1);
    const zoom = (isPlus) => {
      if (isPlus) {
        if (mapScale.value === 2) return;
        mapScale.value += 0.5;
      } else {
        if (mapScale.value === 0.5) return;
        mapScale.value -= 0.5;
      }
    };
    const mapFullScreen = () => {
      document.fullscreenElement
        ? document.exitFullscreen()
        : document.getElementById("map-filling").requestFullscreen();
    };

    const currentMapElements = computed(() =>
      isRussia.value ? russiaMapElements : allMapElements
    );

    const openCurrentObject = (val) => {
      if (currentObject?.value?.id === val?.id) {
        currentObject.value = null;
        return;
      }
      currentObject.value = val;
    };

    onClickOutside(mapWrapper, closeMap);

    return {
      closeMap,
      zoom,
      map,
      mapScale,
      mapWrapper,
      mapFullScreen,
      mapElements,
      allMapElements,
      currentObject,
      openCurrentObject,
      searchName,
      mapTitle,
      isRussia,
      currentMapElements,
      mapCardComponent,
      specificMapElements,

      openObjectInfo: (val) => {
        emit("openObjectInfo", { val, openNavigation: true });
      },
      robotGuideEnable: () => {
        emit("robotGuideEnable");
        closeMap();
      },
    };
  },
};
</script>

<template>
  <div class="map-wrapper" ref="mapWrapper">
    <div class="map-content">

      <div class="map">
        <div class="map-info">
          <p class="map-info-title">{{ mapTitle }}</p>
          <label v-if="!isRussia" for="" class="search">
            <img src="@/assets/icons/search.svg" alt="" class="search-icon" />
            <input v-model="searchName" type="text" class="search-input" placeholder="Поиск по локациям" />
          </label>
          <div class="map-list">
            <Component :is="mapCardComponent" v-for="(item, index) in currentMapElements?.filter(
              (mapEl) =>
                mapEl?.id &&
                (searchName
                  ? mapEl.name.toLowerCase().includes(searchName.toLowerCase())
                  : true)
            )" :is-map="true" :is-russian="isRussia" :is-landing="landing" :current-object="currentObject"
              :map-element="item" :locale="locale" @openObjectInfo="openObjectInfo" :key="index" :index="index"
              @openCurrentObject="openCurrentObject" @closeMap="closeMap" />
          </div>
        </div>
        <div class="map-container-with__title">
          <p v-if="isRussia" class="subtitle">В метавселенной ВДНХ у вас есть возможность посетить шесть павильонов
            международной выставки-форума «Россия». Оцифрованные объекты подсвечиваются синим при наведении на них
            курсора. Изучите, каким достижениям страны посвящена каждая экспозиция</p>
          <div class="map-container map-desktop" id="map-filling">
            <NewMapElementInfo v-if="currentObject" :isGame="true" :locale="locale" :is-landing="landing"
              :current-object="currentObject" @robotGuideEnable="robotGuideEnable"
              @closeInformation="currentObject = null" @closeMap="closeMap" />
            <MapDraggableInGame :current-object="currentObject" :mapElements="specificMapElements" ref="map"
              :locale="locale" :map-scale="mapScale" @openCurrentObject="openCurrentObject" @closeMap="closeMap" />
            <div class="map-management">
              <div class="zoom">
                <button class="management__element zoom-plus" @click="zoom(true)"></button>
                <button class="management__element zoom-minus" @click="zoom(false)"></button>
              </div>
              <button class="management__element position" v-if="false"></button>
              <button class="management__element size" @click="mapFullScreen"></button>
            </div>
          </div>
        </div>
        <button class="map__close" @click="closeMap">
          <img src="@/assets/icons/close-main.svg" alt="" class="close-img" />
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
* {
  box-sizing: border-box;
  font-family: "GolosText";
}

.map-wrapper {
  padding: 0 1rem;
  height: calc(100% - 12rem);
  position: absolute;
  z-index: 3;
  max-width: 100%;
  width: 100%;
  display: flex;
}

.map-content {
  padding: 0.63rem;
  max-width: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 0.5rem;
  background: rgba(157, 196, 248, 0.3);
  backdrop-filter: blur(4px);
  pointer-events: all;
}

.map {
  height: 100%;
  max-width: 100%;
  width: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
  background: #fff;
  display: flex;
  gap: 1rem;
}

.map-container-with__title {
  flex: 1;
  overflow: hidden;
}

.map-container-with__title .subtitle {
  color: #000;
  margin-bottom: 20px;
}

.map-info {
  max-width: 23.875rem;
  width: 100%;
  padding: 1.5rem 1rem;
  border-radius: 1rem;
  background: #ecf2fa;
  display: flex;
  flex-direction: column;
}

.map-info-title {
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.00875rem;
}

.search {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.69rem 1rem;
  border-radius: 0.5rem;
  background: #fff;
}

.search-icon {
  display: flex;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.search-input {
  margin-top: 0;
  padding: 0;
  background: none;
  border-radius: 0;
  border: none;
  color: #abb4c0;
  font-size: 1rem;
}

.map-container {
  display: flex;
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.map__close {
  display: flex;
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
}

.close-img {
  width: 100%;
  height: 100%;
}

.map-list {
  margin-top: 1.5rem;
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;
  overflow: auto;
}

.map-container {
  padding: 2rem;
  border-radius: 1rem;
  background: #ecf2fa;
  display: flex;
  max-width: 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  align-items: center;
  overflow: hidden;
}

.map-management {
  position: absolute;
  right: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
}

.management__element {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
}

.zoom {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.zoom-plus {
  background: url("@/assets/icons/game/zoom-plus.svg") no-repeat center;
  background-size: cover;
}

.zoom-minus {
  background: url("@/assets/icons/game/zoom-minus.svg") no-repeat center;
  background-size: cover;
}

.position {
  background: url("@/assets/icons/game/pozition.svg");
}

.size {
  background: url("@/assets/icons/game/resize.svg") no-repeat center;
  background-size: cover;
  width: 3rem;
  height: 3rem;
}

@media (max-width: 1400px) {
  .management__element {
    width: 2rem;
    height: 2rem;
  }

  .size {
    width: 2rem;
    height: 2rem;
  }
}

@media (max-width: 1000px) {
  .map-wrapper {
    padding: 0 3rem;
    height: calc(100% - 8rem);
  }

  .map-content {
    padding: 0.37rem;
    border-radius: 0.5rem;
  }

  .map {
    padding: 0.5rem;
    border-radius: 0.5rem;
    gap: 0.5rem;
  }

  .map-info {
    padding: 0.5rem;
    border-radius: 0.5rem;
  }

  .map-info-title {
    font-size: 0.75rem;
  }

  .map__close {
    width: 1.5rem;
    height: 1.5rem;
  }

  .map-info {
    max-width: 14rem;
  }

  .search {
    margin-top: 0.5rem;
    padding: 0.5rem;
    gap: 0.5rem;
  }

  .search-icon {
    width: 1rem;
    height: 1rem;
  }

  .map-list {
    margin-top: 0.5rem;
  }
}
</style>
