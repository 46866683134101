<script setup>
import RedesignEventNotification from "@/components/general/RedesignEventNotification.vue";
import RedesignMobileNotification from "@/components/general/RedesignMobileNotification.vue";
import TimeSlotItem from "@/components/schedule/TimeSlotItem.vue";
import DateSlide from "@/components/schedule/DateSlide.vue";
import { timeStringFormat } from "@/lib/convertTime";
import { declination } from "@/lib/declination";
import { targetActionsMap } from "@/lib/sendYmParams";
import dayjs from "dayjs";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import { DatePicker } from "v-calendar";
import "v-calendar/style.css";
import {
  computed,
  onBeforeUnmount,
  onBeforeMount,
  ref,
  shallowRef,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

const KEY = "multi";

const ZERO_TIME_CONFIG = {
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0,
};

const swiperConfig = {
  spaceBetween: 4,
  freeMode: true,
  modules: [Navigation],
  navigation: {
    nextEl: ".dates-button-next",
    prevEl: ".dates-button-prev",
  },

  breakpoints: {
    320: {
      slidesPerView: 2,
    },
    540: {
      slidesPerView: 3,
    },
    768: {
      slidesPerView: 6,
    },
    992: {
      slidesPerView: 7,
    },
    1024: {
      slidesPerView: 9,
    },
  },
};

const MOSCOW = "Europe/Moscow";
const ticketWords = ["билетов", "билет", "билета"];
const accessabilityWords = ["Доступно", "Доступен", "Доступно"];

const { getters, dispatch, commit } = useStore();
const route = useRoute();
const router = useRouter();

const multiSub = computed(() => Object.keys(route.query).includes(KEY));

const modelDayIndex = shallowRef(0);
const modelSlotIndex = shallowRef(null);

const datePickerConfig = computed(() => {
  const dates = Object.values(
    currentEventGroupByDate.value.eventSlots.availableSlots,
  );
  const minDate = dayjs(dates.at(0)?.at(0)?.startTime).tz(MOSCOW).toDate();
  const maxDate = dayjs(dates.at(-1)?.at(0)?.startTime).tz(MOSCOW).toDate();

  return {
    titlePosition: "left",
    minDate,
    maxDate,
    locale: { id: "ru", firstDayOfWeek: 2, masks: { weekdays: "WW" } },
  };
});

const currentEvent = computed(() => getters.currentEvent);
const currentEventGroupByDate = computed(() =>
  groupAvailableSlots(getters.currentEvent),
);

function groupAvailableSlots(event) {
  if (!event?.eventSlots?.availableSlots) {
    return event;
  }

  const availableSlots = event?.eventSlots.availableSlots.map((slot) => {
    const date = dayjs(slot.startTime)
      .tz(MOSCOW)
      .set(ZERO_TIME_CONFIG)
      .format("YYYY-MM-DD");

    return {
      ...slot,
      count: slot.limit - slot.participants.length,
      date,
    };
  });

  const groupSlots = Object.groupBy(availableSlots, ({ date }) => date);

  return {
    ...event,
    eventSlots: {
      ...event.eventSlots,
      availableSlots: groupSlots,
    },
  };
}

const scheduledSlots = computed(() => getters.userData.slots);
const dates = computed(() =>
  Object.keys(currentEventGroupByDate.value.eventSlots.availableSlots),
);
const slots = computed(
  () =>
    Object.values(currentEventGroupByDate.value.eventSlots.availableSlots)?.[
    modelDayIndex.value
    ] ?? [],
);

const currentDate = shallowRef(dayjs().tz(MOSCOW).toDate());
const isShowDatePicker = shallowRef(false);

function toggleDatePicker(state = !isShowDatePicker.value) {
  isShowDatePicker.value = state;
}

const lastBookedSlot = ref(null);

const lastTimeSlot = computed(() => {
  if (!scheduledSlots.value || !currentEvent.value) return null;

  const currentTime = dayjs().unix();

  const { slot } = scheduledSlots.value
    .filter(({ slot }) => currentTime < dayjs(slot.endTime).unix())
    .sort((a, b) => dayjs(a.slot.endTime).unix() - dayjs(b.slot.endTime).unix())
    .find(
      ({ slot }) => slot.eventSlotsId === currentEvent.value.eventSlots.id,
    ) ?? { slot: null };

  return slot;
});

const sessionTimer = shallowRef(0);
const remainingSessionTime = shallowRef(0);

const unsubscribeButton = shallowRef(null);
const disabledUnsubscribeButton = shallowRef(null);

function setUnsubscribeButton(data) {
  unsubscribeButton.value = data;
}

async function onSubscribe() {
  // if (slotIsBegin.value) {
  //   return;
  // }

  if (!selectSlot.value) {
    return;
  }

  try {
    const { startTime, id } = selectSlot.value;

    const date = dayjs(startTime).format("DD.MM.YYYY");
    const time = dayjs(startTime).format("HH:mm");

    targetActionsMap.get(25)(currentEvent.value?.title, date, time);

    const isSubscribed = await dispatch("slotEventSubscribe", {
      slotId: id,
      multiSub: multiSub.value,
    });

    if (!isSubscribed) {
      return;
    }

    sessionStorage.setItem("slotStartTime", startTime);
    sessionStorage.setItem("mapPath", currentEvent.value.mapPath);

    if (lastTimeSlot.value) {
      lastBookedSlot.value = {
        title: currentEvent.value?.title,
        previewUrl: currentEvent.value?.previewUrl,
        startTime: lastTimeSlot?.value.startTime,
      };
      return;
    }

    await dispatch("fetchCurrentEvent", route.params.id);
    await dispatch("fetchUserData");
    lastBookedSlot.value = {
      title: currentEvent.value?.title,
      previewUrl: currentEvent.value?.previewUrl,
      startTime: selectSlot.value?.startTime,
    };
    modelSlotIndex.value = null;
  } catch (error) {
    console.error("Ошибка при подписке:", error);
  }
}

async function onUnsubscribe() {
  if (!unsubscribeButton.value) {
    return;
  }

  if (!lastTimeSlot.value) {
    return;
  }

  try {
    const { startTime } = lastTimeSlot.value;

    const date = dayjs(startTime).format("DD.MM.YYYY");
    const time = dayjs(startTime).format("HH:mm");
    targetActionsMap.get(26)(
      currentEvent.value?.title,
      date,
      time,
      "Отписаться",
    );

    sessionStorage.removeItem("mapPath");
    disabledUnsubscribeButton.value = true;

    await dispatch(
      "slotEventUnsubscribe",
      unsubscribeButton.value.participants[0],
    );
    await dispatch("fetchUserData");

    scheduled.value = null;

    sessionStorage.removeItem("currentSlot");

    unsubscribeButton.value = null;
    remainingSessionTime.value = 0;
    clearInterval(sessionTimer.value);
    disabledUnsubscribeButton.value = false;

    await dispatch("fetchCurrentEvent", route.params.id);
  } catch (error) {
    console.error("Ошибка при отписке:", error);
  }
}

watch(currentDate, (value) => {
  const date = dayjs(value).tz(MOSCOW).format("YYYY-MM-DD");
  const index = dates.value.findIndex((i) => i === date);

  if (index === modelDayIndex.value) {
    return;
  }

  if (dates.value[index]) {
    toggleDatePicker(false);
    modelDayIndex.value = index;
    swiperInstance.value?.slideTo(index);
    toggleDatePicker(false);
  }
});

watch(() => lastTimeSlot.value?.id,
  () => {
    if (dayjs() > dayjs(lastTimeSlot.value?.startTime)) {
      remainingSessionTime.value = dayjs(lastTimeSlot.value?.endTime) - dayjs();

      sessionTimer.value = setInterval(() => {
        remainingSessionTime.value--;
        if (remainingSessionTime.value <= 0) {
          remainingSessionTime.value = 0;
          clearInterval(sessionTimer.value);
        }
      }, 1000);
    }
  },
);

const selectSlot = computed(() => slots.value?.[modelSlotIndex.value] ?? null);

// const slotIsBegin = computed(() => {
//   if (!selectSlot.value) {
//     return false;
//   }

//   const currentTime = +dayjs().tz(MOSCOW).toDate();
//   const startSlotTime = +dayjs(selectSlot.value.startTime).tz(MOSCOW).toDate()

//   return currentTime > startSlotTime;
// })

const scheduled = shallowRef(null);

function checkScheduled(item, value) {
  const isScheduled = scheduledSlots.value.find(
    (slot) => slot.slotId === item.id,
  );

  const data = {
    item,
    value,
  };

  if (isScheduled) {
    const currentTime = dayjs().tz(MOSCOW);
    const endTime = dayjs(isScheduled.slot.endTime).tz(MOSCOW);

    if (currentTime.isBefore(endTime)) {
      scheduled.value = isScheduled;
    }

    data.title = "Вы уже записаны";
    data.disabled = true;
  }

  return data;
}

const allRemainingTickets = computed(() => {
  const quantity =
    slots.value?.reduce((sum, current) => (sum += current.count), 0) ?? 0;

  const accessabilityWord = declination(quantity, accessabilityWords);
  const ticketWord = declination(quantity, ticketWords);

  return `${accessabilityWord} ${quantity} ${ticketWord}`;
});

watch(
  () => Number(modelDayIndex.value),
  (value) => {
    const date = dayjs(dates.value[value]?.startTime)?.format("DD-MM-YYYY");
    currentDate.value = dayjs(dates.value[value]).toDate();
    targetActionsMap.get(23)(currentEvent.value?.title, date);
    modelSlotIndex.value = null;
  },
);

const timer = shallowRef(null);

function onSlotClick(item) {
  if (timer.value) {
    return;
  }

  timer.value = setTimeout(() => {
    clearTimeout(timer.value);
    timer.value = null;
  });

  const date = dayjs(item.startTime).format("DD.MM.YYYY");
  const time = dayjs(item.startTime).format("HH:mm");

  targetActionsMap.get(24)(currentEvent.value?.title, date, time);
}

const openAlert = shallowRef(false);

function onCloseAlert() {
  openAlert.value = false;
}

function goLobby() {
  if (window.innerWidth < 768) {
    openAlert.value = true;

    return;
  }

  const query = currentEvent.value.eventPlace?.length
    ? { place: currentEvent.value.eventPlace }
    : {};

  if (lastTimeSlot.value) {
    return router.push({
      name: "lobbyPage",
      params: {
        id: currentEvent.value?.id,
        slotId: lastTimeSlot.value.id,
      },
      query,
    });
  }

  return router.push({
    name: "lobbyPage",
    params: {
      id: currentEvent.value?.id,
    },
    query,
  });
}

function goLobbyWithTarget(buttonName) {
  const date = dayjs(lastTimeSlot.value.startTime).format("DD.MM.YYYY");
  const time = dayjs(lastTimeSlot.value.startTime).format("HH:mm");
  targetActionsMap.get(26)(currentEvent.value?.title, date, time, buttonName);

  goLobby();
}

const swiperInstance = shallowRef();

function onSwiper(swiper) {
  swiperInstance.value = swiper;
}

const loading = shallowRef(false);

onBeforeMount(async () => {
  try {
    loading.value = true;
    await dispatch("fetchCurrentEvent", route.params.id);
  } finally {
    loading.value = false;
  }
});

onBeforeUnmount(() => {
  commit("closeWS");
  commit("setCurrentEvents", null);
});
</script>

<template>
  <section class="section date purple-bg">
    <div class="date-filling container">
      <div class="caption">
        <RouterLink to="/cabinet/main">
          <button class="back back--desk">
            <img src="@/assets/new-icons/arrow-right.svg" alt="Назад" class="back__icon" />
          </button>
          <button class="back back--mob">
            <img src="@/assets/new-icons/ArrowLeft-gray.svg" alt="Назад" class="back__icon" />
          </button>
        </RouterLink>
        <p class="caption__title title-h2">Выбор билетов</p>
      </div>
      <div class="filling">
        <Transition name="fade" mode="out-in">
          <div v-if="!loading" key="content">
            <div class="filling__title title-h1">
              {{ currentEvent?.title }}
              <div class="calendar calendar-desktop">
                <button @click="toggleDatePicker()">
                  <img src="@/assets/new-icons/calendar.svg" alt="иконки календаря" />
                </button>
                <Transition name="fade">
                  <div v-if="isShowDatePicker" class="calendar-date-picker">
                    <DatePicker v-model="currentDate" v-bind="datePickerConfig" />
                  </div>
                </Transition>
              </div>
            </div>
            <p class="filling__description text">
              {{ currentEvent?.description }}
            </p>
            <p class="date-preview__alert">
              Проект работает в пилотном режиме. Экскурсии и прогулки по
              МетаВДНХ доступны только на компьютере
            </p>
            <div class="dates-wrapper">
              <Swiper v-bind="swiperConfig" class="dates" @swiper="onSwiper">
                <SwiperSlide v-for="(dateString, index) in dates" :key="dateString" class="dates-element">
                  <DateSlide v-model="modelDayIndex" :date-string="dateString" :index="index" />
                </SwiperSlide>
              </Swiper>
              <div class="dates-button-wrapper">
                <button class="dates-button dates-button-next">
                  <img src="@/assets/new-icons/dates-arrow.svg" alt="next arrow" />
                </button>
                <button class="dates-button dates-button-prev">
                  <img src="@/assets/new-icons/dates-arrow.svg" alt="prev arrow" />
                </button>
              </div>
            </div>
            <div class="filling__text text remaining-flex">
              {{ allRemainingTickets }}

              <div class="calendar calendar-mobile">
                <button @click="toggleDatePicker()">
                  <img src="@/assets/new-icons/calendar.svg" alt="иконки календаря" />
                </button>
                <Transition name="fade">
                  <div v-if="isShowDatePicker" class="calendar-date-picker">
                    <DatePicker v-model="currentDate" v-bind="datePickerConfig" />
                  </div>
                </Transition>
              </div>
            </div>
            <div class="slots">
              <Transition name="fade" mode="out-in">
                <div :key="modelDayIndex" class="slots-filling">
                  <TimeSlotItem v-for="(item, index) in slots" v-bind="checkScheduled(item, index)"
                    v-model="modelSlotIndex" :key="item.id" :value="index" @slot="setUnsubscribeButton"
                    @click="onSlotClick(item)" />
                </div>
              </Transition>
              <div class="slots-action" v-if="remainingSessionTime <= 0 || multiSub">
                <!--  || slotIsBegin -->
                <button v-if="unsubscribeButton" :disabled="disabledUnsubscribeButton" class="slots__btn"
                  @click.prevent.stop="onUnsubscribe">
                  Отписаться
                </button>
                <button v-if="!scheduled || multiSub" :disabled="!selectSlot || !selectSlot?.limit" class="slots__btn"
                  @click="onSubscribe">
                  Записаться
                </button>
                <button v-if="currentEvent?.id" class="slots__btn slots__btn--yellow slots__btn--desktop"
                  :class="{ 'slots__btn--disabled': !lastTimeSlot }" @click="goLobbyWithTarget('Перейти на локацию')">
                  Перейти на локацию
                </button>
              </div>
              <div v-else class="slots-action">
                <button v-if="unsubscribeButton" :disabled="disabledUnsubscribeButton" class="slots__btn"
                  @click.stop.prevent="onUnsubscribe">
                  Отписаться
                </button>
                <button v-if="currentEvent?.id" class="slots__btn slots__btn--yellow slots__btn--desktop"
                  :class="{ 'slots__btn--disabled': !lastTimeSlot }" @click="goLobbyWithTarget('Присоединиться')">
                  Присоединиться ({{ timeStringFormat(remainingSessionTime) }})
                </button>
              </div>
            </div>
            <small class="filling__text filling__text--red">Время соответствует вашему часовому поясу</small>
          </div>
          <div v-else class="loader" key="loader">
            <div class="loader-spinner" />
          </div>
        </Transition>
      </div>
    </div>
    <RedesignEventNotification v-if="lastBookedSlot" @close="lastBookedSlot = null" :current-slot="lastBookedSlot">
    </RedesignEventNotification>
    <RedesignMobileNotification v-if="openAlert" @close="onCloseAlert" />
  </section>
</template>

<style scoped lang="scss">
.date {
  min-height: calc(100vh - 189px);
  padding: 3rem 0;

  &-preview__alert {
    display: none;
    margin-top: 0.75rem;
    padding: 1.25rem;
    font-size: 1.1rem;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #fff;
    border-radius: 2rem;
    background: #d72d41;

    @media (max-width: 767px) {
      display: block;
    }
  }

  @media (max-width: 1100px) {
    background: none;
  }
}

.remaining-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.title-h1.content-center {
  justify-content: center;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px auto;

  &-spinner {
    width: 48px;
    height: 48px;
    border: 5px solid #fff;
    border-bottom-color: #013b99;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
}

.calendar {
  margin-left: auto;
  position: relative;

  &-desktop {
    @media (max-width: 767px) {
      display: none;
    }
  }

  &-mobile {
    display: none;

    @media (max-width: 767px) {
      display: block;
    }
  }

  &-date-picker {
    position: absolute;
    top: 50px;
    right: 0;
    z-index: 10;

    & .vc-blue {
      --vc-accent-50: #f0f9ff;
      --vc-accent-100: #e0f2fe;
      --vc-accent-200: #bae6fd;
      --vc-accent-300: #7dd3fc;
      --vc-accent-400: #38bdf8;
      --vc-accent-500: #0ea5e9;
      --vc-accent-600: green !important;
      --vc-accent-700: #0369a1;
      --vc-accent-800: #075985;
      --vc-accent-900: #0c4a6e;
    }
  }
}

.title {
  &-h1 {
    font-weight: 500;
    font-size: 2rem;
    line-height: 110%;
    color: #13151a;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      font-size: 1.75rem;
    }
  }

  &-h2 {
    font-weight: 500;
    font-size: 1.625rem;
    line-height: 125%;
    color: #13151a;

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }
}

.caption {
  display: flex;
  align-items: center;
  gap: 1.5rem;

  &--mob {
    display: none;
  }

  @media (max-width: 1100px) {
    gap: 0.75rem;

    &--desk {
      display: none;
    }

    &--mob {
      display: flex;
    }

    &__title {
      font-size: 1rem;
      line-height: 140%;
      color: #828282;
    }
  }
}

.back {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
  }

  &--mob {
    display: none;
  }

  @media (max-width: 1100px) {
    &--desk {
      display: none;
    }

    &--mob {
      display: flex;
    }
  }
}

.filling {
  margin-top: 1.5rem;
  padding: 2rem;
  border: 1px solid #f2f2f2;
  border-radius: 1.5rem;
  background: #fff;

  &__description {
    margin-top: 2.25rem;
    font-size: 1.125rem;
    line-height: 140%;
    color: #13151a;
  }

  &__text {
    margin-top: 1.25rem;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 110%;
    color: #666;

    &--red {
      display: block;
      margin-top: 15px;
      color: #d72d41;
    }
  }

  @media (max-width: 1100px) {
    margin-top: 2rem;
    border: none;
    padding: 0;

    &__title {
      font-size: 1.5rem;
      line-height: 130%;
      letter-spacing: 0.01em;
      color: #13151a;
    }

    &__text {
      margin-top: 1.5rem;
      font-weight: 400;
      font-size: 0.88rem;
      line-height: 130%;
    }

    &__description {
      margin-top: 1rem;
      font-weight: 400;
      font-size: 0.88rem;
      line-height: 130%;
      color: #000;
    }
  }
}

.dates {
  margin-top: 1.25rem;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  gap: 0.25rem;

  &-wrapper {
    position: relative;
  }

  @media (max-width: 768px) {
    margin-top: 1.5rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.75rem;
  }

  @media (max-width: 500px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 400px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  &-element {
    padding: 0.75rem 0.75rem 0.875rem 0.75rem;
    max-width: 8.625rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    @media (max-width: 768px) {
      max-width: 100%;
      width: 100%;
    }

    &:hover {
      .dates-element__bg {
        background: #00288c;

        @media (max-width: 768px) {
          background: #fff;
        }
      }

      .dates-element__day {
        color: rgba(255, 255, 255, 0.64);

        @media (max-width: 768px) {
          color: #13151a;
        }
      }

      .dates-element__date {
        color: #fff;

        @media (max-width: 768px) {
          color: #13151a;
        }
      }

      .dates-element__month {
        color: rgba(255, 255, 255, 0.64);

        @media (max-width: 768px) {
          color: #13151a;
        }
      }
    }
  }

  &-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    left: -2rem;
    display: flex;

    @media (max-width: 768px) {
      left: 0;
    }

    &-next {
      right: -2rem;
      transform: translateY(-50%) rotate(180deg);
      left: unset;

      @media (max-width: 768px) {
        right: 0;
      }
    }
  }
}

.slots {
  margin-top: 0.75rem;
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  justify-content: space-between;

  @media (max-width: 1100px) {
    flex-direction: column;
  }

  &-filling {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;

    @media (max-width: 768px) {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      max-width: 100%;
      width: 100%;
    }

    @media (max-width: 500px) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 400px) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &-element {
    text-align: center;

    @media (max-width: 768px) {
      max-width: 100%;
      width: 100%;
    }

    &__content {
      display: flex;
      cursor: pointer;
      padding: 0.75rem;
      border-radius: 0.25rem;
      position: relative;
      justify-content: center;

      @media (max-width: 768px) {
        max-width: 100%;
        width: 100%;
      }

      &:hover {
        .slots-element__bg {
          background: #a5c3f0;
        }
      }
    }

    &__text {
      font-size: 0.875rem;
      line-height: 100%;
      text-align: center;
      color: #13151a;
      position: relative;
      z-index: 2;
      transition: all 1s ease;
    }

    &__input {
      margin-top: 0;
      position: absolute;
      opacity: 0;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      &:checked~.slots-element__bg {
        background: #a5c3f0;
      }

      &:checked~.slots-element__text {
        color: #a5c3f0;
      }
    }

    &__bg {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      border: 1px solid #f5f6f7;
      border-radius: 0.25rem;
      background: #f5f6f7;
      transition: all 1s ease;
    }

    &__number {
      margin-top: 0.5rem;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 110%;
      color: #666;
    }
  }

  &-action {
    display: flex;
    gap: 0.5rem;
    flex-shrink: 0;

    @media (max-width: 767px) {
      width: 100%;
    }

    @media (max-width: 450px) {
      flex-direction: column;
      max-width: 100%;
      width: 100%;
    }
  }

  &__btn {
    padding: 0.75rem 1rem;
    background: #d72d41;
    font-size: 0.875rem;
    line-height: 114%;
    color: #fff;
    border-radius: 12.5rem;
    text-align: center;

    @media (max-width: 767px) {
      width: 100%;
    }

    &--desktop {
      @media (max-width: 767px) {
        display: none;
      }
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &--disabled {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }

    &--yellow {
      background: #d72d41;
      color: #fff;
    }

    @media (max-width: 450px) {
      max-width: 100%;
      width: 100%;
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
