<template>
  <div :class="`element-info element-info__open ${currentObject.elementInfoClass}`">
    <div class="element-content content__open">
      <div class="element-demonstration-wrapper">
        <img :src="currentObject.imgUrl" alt="" class="element-demonstration">
      </div>
      <div class="element-filling">
        <div class="caption map-desktop">
          <p class="element-title title title-small ">{{
            locale === 'en' ? currentObject?.name_eng : currentObject?.name
          }}</p>
          <button class="close" @click="closeInformation">
            <img src="@/assets/icons/close-main.svg" alt="" class="close-icon">
          </button>
        </div>
        <p class="element-text text">{{
          (locale === 'en' ? currentObject?.description_eng : currentObject?.description)
        }}</p>
        <template v-if="!isLanding">
          <button v-if="currentObject?.id && typeof currentObject?.id === 'number' && !currentObject?.cantTeleportation"
            @click="teleportToLocation()" class="link-default">{{ $t("game.controls.teleportToLocation") }}
          </button>
          <button
            v-if="currentObject?.id && typeof currentObject?.id === 'number' && currentObject?.entranceTeleportId != null"
            @click="teleportToLocation(true)" class="link-default" style="margin-top: 0.5rem ">{{
              $t("game.controls.teleportToEntrance") }}
          </button>
          <!-- <button class="link-default link-default--last" v-if="isGame && showOpenGuideButton" @click="openGuide">
          {{ $t("game.controls.openGuide") }}
        </button> -->
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { appConfig } from "@/lib/appConfig";
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "NewMapElementInfo",
  computed: {
    appConfig() {
      return appConfig
    }
  },
  props: ['currentObject', 'isGame', 'locale', 'isLanding'],
  emits: [
    'closeInformation',
    'robotGuideEnable',
    'closeMap',
  ],
  setup(props, { emit }) {
    const { commit, state } = useStore();
    let route = useRoute();
    const router = useRouter();

    const showOpenGuideButton = computed(() => ['', 'L_VDNH_WP'].includes(state.application.currentLevelName));
    const teleportToLocation = async (isEntrance = false) => {
      const teleportId = isEntrance ? props.currentObject?.entranceTeleportId : props.currentObject?.id

      commit('stIsVdnh', teleportId);
      if (route.name !== 'gamePage') {
        sessionStorage.setItem('teleportId', teleportId);
        await router.push({ name: 'gamePage' });
      } else {
        document.dispatchEvent(new CustomEvent("Teleport", { detail: teleportId }));
      }
      emit('closeMap');
    }
    return {
      teleportToLocation,
      showOpenGuideButton,
      closeInformation: () => {
        emit('closeInformation');
      },
      openGuide: () => {
        document.dispatchEvent(new CustomEvent("CreateRoboGuide", { detail: props.currentObject?.id }));
        emit('robotGuideEnable');
      }
    }
  }
}
</script>


<style scoped>
.element-info {
  border-radius: 1rem;
  padding: 1.5rem;
  width: 42.25rem;
  background: #FFF;
  filter: drop-shadow(0px 10px 16px rgba(69, 94, 128, 0.32));
  position: absolute;
  left: 1rem;
  top: 1rem;
  opacity: 0;
  transform: translateY(10%);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  z-index: -1;
  cursor: auto;
}

.element-content {
  display: flex;
  gap: 1.5rem;
}

.element-info__open {
  opacity: 1;
  transform: translateY(0%);
  z-index: 2;
}

.element-filling {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
}


.caption {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: space-between;

}

.close {

  display: flex;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.element-title {
  color: #000;
}

.element-text {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  max-height: 20rem;
  overflow: auto;
}


.link-default {
  margin-top: auto;
}

.link-default--last {
  margin-top: 0.5rem;
}


.element-demonstration-wrapper {
  position: relative;
  max-width: 13.75rem;
  width: 100%;
  border-radius: 0.5rem;
  height: 13.75rem;
  display: flex;
  flex-shrink: 0;
}

.element-demonstration {
  border-radius: 0.5rem;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center;

}


@media (max-width: 1500px) {
  .element-demonstration-wrapper {
    max-width: 10.75rem;

    height: 10.75rem;
  }

  .element-title {
    font-size: 1.25rem;
  }

  .element-text {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .link-default {
    font-size: 0.875rem;
    padding: 0.75rem;
  }
}


.element2 .element-info {
  width: 57rem;
}


.element3 .element-info {
  width: 47rem;
}


.element4 .element-info {
  width: 52rem;
}

.element-info-2 .caption {
  align-items: flex-start;
}

.element-info-2 .close {
  margin-top: 6px;
}


@media (max-width: 1500px) {


  .element1 .element-button {
    width: 8.25rem;
  }

  .element2 {
    top: -12.5rem;
    left: -24.19rem;
  }

  .element2 .element-button {
    width: 12rem;
  }

  .element2 .element-info {
    width: 49rem;
  }

  .element3 {
    top: 4.5rem;
    left: -31rem;
  }

  .element3 .element-button {
    width: 8rem;
  }

  .element3 .element-info {
    width: 44rem;
  }

  .element4 {
    right: 1.6rem;
    bottom: -1rem;
  }

  .element4 .element-button {
    width: 11rem;
  }

  .element4 .element-info {
    width: 49rem;
  }
}

@media (max-width: 1200px) {


  .element-info-1 {
    width: 36rem;
  }

  .element-info-2 {
    width: 37rem;
  }

  .element-info-3 {
    width: 36rem;
  }

  .element-info-4 {
    width: 36rem;
  }

}

@media (max-width: 1000px) {
  .element-info {
    left: 0.5rem;
    top: 0.5rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    width: 60%;
  }

  .element-demonstration-wrapper {
    width: 5rem;
    height: 5rem;
  }

  .element-content {

    gap: 0.5rem;
  }

  .element-title {
    font-size: 0.75rem;
  }

  .element-text {
    margin: 0.25rem 0;
    font-size: 0.65rem;
  }

  .link-default {
    font-size: 0.65rem;
    padding: 0.4rem;
  }

  .close {
    width: 1rem;
    height: 1rem;
  }
}
</style>