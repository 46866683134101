<script setup>
import { russiaMapNames } from '@/lib/russia';
import { computed, defineEmits, shallowRef, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from "vuex";

const emits = defineEmits({
  openPavilion: () => true,
});

const store = useStore();
const route = useRoute();
const showClue = shallowRef(false)
const isRussia = computed(() => route.query.place === 'russia');
const showLoadingScreen = computed(() => store.state.application.showLoadingScreen);
const currentLevelName = computed(() => store.state.application.currentLevelName.trim());
const isRussiaPavilion = computed(() => russiaMapNames.includes(currentLevelName.value));


watch(showLoadingScreen, (screen) => {
  if (!screen && isRussia.value) {
    showClue.value = isRussiaPavilion.value && currentLevelName.value !== russiaMapNames.at(-2);

    if (isRussiaPavilion.value) {
      emits('openPavilion')
    }
  }
});
</script>

<template>
  <Transition name="fade">
    <div v-show="showClue" class="clue">
      <img src="@/assets/new-icons/game/clue.svg" alt="" class="clue__icon">
      <p class="clue__text">В МетаВДНХ представлены оригинальные аудиогиды по павльонам, которые были доступны в дни
        проведения Международной выставки-форума «Россия»</p>
    </div>
  </Transition>
</template>

<style scoped>
.clue {
  position: absolute;
  bottom: .875rem;
  left: 10vw;
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 10;
}

.clue__icon {
  width: 1.5rem;
  height: 1.5rem;
}

.clue__text {
  font-weight: 400;
  font-size: 0.62rem;
  letter-spacing: -0.01em;
  color: #fff;
  max-width: 17.56rem;
}
</style>
