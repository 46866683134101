export default [
  {
    id: 1,
    class: "cibur-fill",
    title: "Добро пожаловать в павильон «Дом полимеров» СИБУР!",
    mapImg: require("@/assets/pavilions/Cibur.webp"),
    logo: require("@/assets/pavilions/cibur-logo.svg"),
    elements: [
      {
        name: "Первый зал",
        name_eng: "The first hall",
        description: "Экспозиция павильона СИБУР рассказывает о том, как создаются материалы, которые делают нашу жизнь комфортной, безопасной и интересной. Только вдумайтесь: 8 из 10 предметов вокруг нас состоят из полимеров! 98% продуктов в мире производится с помощью химии. И для нашего комфортного быта: долговечные трубы для ЖКХ, утеплители, шумоизоляция, посуда, клей, лаки, декор – можно продолжать бесконечно.",
        description_eng: "The exposition of the Pavilion Sibur talks about how the materials are created that make our life comfortable, safe and interesting.Just think: 8 out of 10 items around us consist of polymers!98% of products in the world are produced using chemistry.And for our comfortable life: durable pipes for housing and communal services, insulation, noise insulation, dishes, glue, varnishes, decor - you can continue endlessly.",
        category: "1 - Зал",
        width: "111",
        height: "128",
        left: "192",
        top: "58",
        imgUrl: require("@/assets/pavilions/cibur-img-1.webp"),
        id: 113,
      },
      {
        name: "Большая трансформация",
        name_eng: "The first hall",
        description: "Этот зал рассказывает, как создаются полимеры и где их можно встретить в повседневной жизни. Первые синтетические материалы появились в России всего несколько десятилетий назад и со временем изменили жизнь десятков миллионов людей. Сегодня из маленькой гранулы полимер можно превратить в многие привычные нам предметы быта, одежду, автомобили и даже медицинские средства. Посмотрите небольшой фильм – и вы узнаете о том, как развивалась советская нефтехимическая промышленность, с чем связано появление СИБУРа и какие новые задачи стоят перед российскими нефтехимиками.",
        description_eng: "This hall tells how polymers are created and where they can be found in everyday life.The first synthetic materials appeared in Russia just a few decades ago and eventually changed the lives of tens of millions of people.Today, from the small granules, polymer can be turned into many household items, clothes, cars and even medical products.Watch a small film - and you will learn about how the Soviet petrochemical industry developed, which is related to the appearance of Siber and what new tasks are facing Russian petrochemists.",
        category: "2 - Зал",
        width: "146",
        height: "164",
        left: "10",
        top: "105",
        imgUrl: require("@/assets/pavilions/cibur-img-2.webp"),
        id: 114,
      },
      {
        name: "Мастера нефтехимии",
        name_eng: "The first hall",
        description: "В этом зале вы увидите тех, кто выбрал химию и нефтехимию делом своей жизни: Дмитрия Ивановича Менделеева и молодых профессионалов, которые трудятся в СИБУРе сегодня. А еще узнаете о том, какие профессии востребованы на производстве: от инженеров и математиков – до специалистов в сфере искусственного интеллекта и дизайнеров.",
        description_eng: "In this hall you will see those who have chosen chemistry and petrochemistry of their life: Dmitry Ivanovich Mendeleev and young professionals who work in Sibur today.And you will also learn which professions are in demand in production: from engineers and mathematicians - to specialists in the field of artificial intelligence and designers.",
        category: "3 - Зал",
        width: "109",
        height: "61",
        left: "191",
        top: "-3",
        imgUrl: require("@/assets/pavilions/cibur-img-3.webp"),
        id: 115,
      },
      {
        name: "Страна СИБУР",
        name_eng: "The first hall",
        description: "На стенах этого зала — география СИБУРа от Санкт-Петербурга до Благовещенска. Всего на интерактивную карту нанесены более 20 регионов присутствия компании. Вы сможете узнать, как города по всей стране вносят вклад в развитие полимерной промышленности и как СИБУР помогает этим городам становиться еще комфортнее для жизни.",
        description_eng: "On the walls of this hall are the geography of Sibur from St. Petersburg to Blagoveshchensk.In total, more than 20 regions of the presence of the company have been applied to the interactive map.You can find out how cities throughout the country contribute to the development of the polymer industry and how Siber helps these cities become even more comfortable for life.",
        category: "4 - Зал",
        width: "122",
        height: "122",
        left: "339",
        top: "98",
        imgUrl: require("@/assets/pavilions/cibur-img-4.webp"),
        id: 116,
      },
      {
        name: "Экология / Практикум",
        name_eng: "The first hall",
        description: "Какие предметы можно получить из переработанного пластика? От новой бутылки для воды – до баскетбольного мяча! Подробнее о том, что делают из вторсырья и почему важно правильно сортировать отходы, вы узнаете в пятом зале. А ещё вы увидите капсулу сна, аналог которой используют сотрудники СИБУРа на производствах.",
        description_eng: "What items can be obtained from processed plastic?From a new water bottle - to a basketball!Read more about what is done from recyclables and why it is important to correctly sort waste, you will find out in the fifth hall.And you will see a sleep capsule, an analogue of which is used by Sibura employees in production.",
        category: "5 - Зал",
        width: "172",
        height: "122",
        left: "177",
        top: "-172",
        imgUrl: require("@/assets/pavilions/cibur-img-5.webp"),
        id: 117,
      },
    ]
  },
  {
    id: 2,
    class: "russia-fill",
    title: "Добро пожаловать в павильон «РОССИЯ-СТРАНА ВОЗМОЖНОСТЕЙ»",
    mapImg: require("@/assets/pavilions/Russia.webp"),
    elements: [
      {
        name: "Погружение в контекст",
        name_eng: "Immersion in the context",
        description: "Это начальная точка нашего путешествия, в ходе которого вы узнаете, как стремление к познанию и смелость способны изменить мир. В 1961 году Юрий Гагарин воплотил мечту всего человечества и совершил первый полет в космос. Это событие стало не только результатом усилий миллионов ученых, инженеров, конструкторов и людей в целом, но и положило начало освоению космоса. Сегодня человечество уже изучает с помощью сверхчувствительных телескопов дальние звезды галактики, открывает новые планеты, запускает в космос туристов и отправляет ракеты к Марсу. ",
        description_eng: "This is the starting point of our journey, during which you will learn how the desire for knowledge and courage can change the world.In 1961, Yuri Gagarin embodied the dream of all mankind and made the first flight into space.This event was not only the result of the efforts of millions of scientists, engineers, designers and people as a whole, but also laid the foundation for the development of space.Today, humanity is already studying with the help of hypersensitive telescopes of the long -distance stars of the galaxy, opens new planets, launches tourists into space and sends missiles to Mars.",
        category: "1 - Зал",
        width: "204",
        height: "95",
        left: "122",
        top: "95",
        imgUrl: require("@/assets/pavilions/rus-img-1.webp"),
        id: 147,
      },
      {
        name: "Интро. Голос из будущего",
        name_eng: "Интро. Голос из будущего",
        description: "В первом зале вы сможете познакомиться с проводником по павильону – девочкой Верой из 2133 года. Она расскажет, как наследие российских ученых, изобретателей, поэтов, художников, писателей, спортсменов и других деятелей повлияло на становление и развитие России. ",
        description_eng: "In the first room you can get acquainted with the conductor by the pavilion - a girl by faith from 2133.She will tell how the heritage of Russian scientists, inventors, poets, artists, writers, athletes and other figures influenced the formation and development of Russia.",
        category: "2 - Зал",
        width: "107",
        height: "96",
        left: "11",
        top: "94",
        imgUrl: require("@/assets/pavilions/rus-img-8.webp"),
        id: 148,
      },
      {
        name: "Патриотизм ",
        name_eng: "Патриотизм",
        description: "В этом зале вы узнаете, как связаны между собой достижения деятелей науки и культуры разных времен. Так, творчество Александра Пушкина в XIX веке легло в основу многих произведений композитора Петра Чайковского. А те, в свою очередь, «помогли» Дмитрию Менделееву завершить в 1869 году периодическую систему химических элементов, которой сегодня пользуются ученые всего мира. Благодаря его открытиям в области химии менее века спустя Сергей Королёв построил ракету, которая вывела на околоземную орбиту первый в истории человечества искусственный спутник Земли.",
        description_eng: "In this hall you will learn how the achievements of science and culture of different times are interconnected.So, the work of Alexander Pushkin in the 19th century formed the basis of many works of composer Peter Tchaikovsky.And those, in turn, “helped” Dmitry Mendeleev to complete in 1869 a periodic system of chemical elements that scientists around the world use today.Thanks to his discoveries in the field of chemistry less than a century later, Sergei Korolev built a rocket, which brought to the near -Earth orbit the first artificial satellite of the Earth in the history of mankind.",
        category: "3 - Зал",
        width: "107",
        height: "174",
        left: "11",
        top: "-84",
        imgUrl: require("@/assets/pavilions/rus-img-7.webp"),
        id: 149,
      },
      {
        name: "Экология и природные богатства",
        name_eng: "Экология и природные богатства",
        description: "В третьем зале вы сможете больше узнать о природном многообразии и богатстве России. Озеро Байкал считается самым глубоким озером в мире – его глубина достигает 1642 метра, что сравнимо с высотой шести «Башен Федерация» в Москве. А площадь озера сравнима с размерами некоторых стран: Нидерландов, Бельгии или Дании. А рядом с этим древнейшим озером простирается Восточно-Сибирская тайга – второй по площади лес на планете. Она занимает почти 4 миллиона квадратных километров и здесь могло бы поместиться 1500 таких городов, как Москва. ",
        description_eng: "In the third hall you can learn more about the natural diversity and wealth of Russia.Lake Baikal is considered the deepest lake in the world - its depth reaches 1642 meters, which is comparable to the height of six “Bashen Federation” in Moscow.And the area of ​​the lake is comparable to the size of some countries: the Netherlands, Belgium or Denmark.And next to this ancient lake, the East Siberian taiga extends-the second largest forest on the planet.It takes almost 4 million square kilometers and here 1,500 cities such as Moscow could fit here.",
        category: "4 - Зал",
        width: "107",
        height: "106",
        left: "11",
        top: "-194",
        imgUrl: require("@/assets/pavilions/rus-img-4.webp"),
        id: 150,
      },
      {
        name: "Наука и технологический прорыв ",
        name_eng: "Наука и технологический прорыв ",
        description: "Этот зал посвящен научным достижениям, которые стали реальностью благодаря смелым мечтам и упорному труду. Вы узнаете о том, что микробиологи находят лекарства от болезней с помощью генной инженерии и других исследований, как моделирование полета самолета при нагрузках вплоть до сверхзвуковых скоростей помогает при разработке, испытании и запуске новых аппаратов, как исследования в области термоядерного синтеза способны дать человеку практически неиссякаемый источник энергии.",
        description_eng: "This hall is dedicated to scientific achievements, which have become reality thanks to bold dreams and hard work.You will learn that microbiologists find medicines for diseases with genetic engineering and other studies, as modeling the aircraft at loads up to supersonic speeds helps when developing, testing and starting new devices, as research in the field of thermonuclear synthesis can give a person a practically inexhaustibleEnergy source.",
        category: "5 - Зал",
        width: "203",
        height: "69",
        left: "122",
        top: "-195",
        imgUrl: require("@/assets/pavilions/rus-img-3.webp"),
        id: 151,
      },
      {
        name: "Сила характера",
        name_eng: "Сила характера",
        description: "В 5 зале на примере историй о выдающихся современниках – спортсменах, врачах, ученых, артистах – вы узнаете о том, как сила характера помогает не сдаваться на пути к цели и преодолевать все препятствия. Среди героев этого зала – гимнастки Дина и Арина Аверины, прима-балерина Большого театра Светлана Захарова, дирижер Юрий Башмет, артисты Диана Гурцкая и Олег Газманов, кардиохирург Лео Бокерия, онколог Анна Пайчадзе и многие другие.",
        description_eng: "In the 5th hall, on the example of stories about outstanding contemporaries - athletes, doctors, scientists, artists - you will learn about how the strength of character helps not to give up on the way to the goal and overcome all obstacles.Among the heroes of this hall are the gymnasts of Dean and Arina Averina, the Bollerina Prima Bolshoi Theater Svetlana Zakharova, conductor Yuri Bashmet, artists Diana Gurtskaya and Oleg Gazmanov, cardiac surgeon Leo Bokeria, oncologist Anna Paychadze and many others.",
        category: "6 - Зал",
        width: "112",
        height: "137",
        left: "339",
        top: "-186",
        imgUrl: require("@/assets/pavilions/rus-img-2.webp"),
        id: 152,
      },
      {
        name: "Мы вместе!",
        name_eng: "Мы вместе!",
        description: "Экспозиция этого зала станет местом, где мечты множества людей становятся частью единого целого. Россия объединяет 89 регионов и сохраняет индивидуальность и национальный характер каждого из них. Наша страна состоит из 24 республик, 9 краёв, 48 областей, 3 города федерального значения, 4 автономных округов и 1 автономнов области. Россия – единство множества уникальных элементов, каждый из которых важен и значим.",
        description_eng: "The exposition of this hall will become a place where the dreams of many people become part of a single whole.Russia unites 89 regions and retains the individuality and national character of each of them.Our country consists of 24 republics, 9 edges, 48 ​​regions, 3 cities of federal significance, 4 autonomous districts and 1 autonomous regions.Russia is the unity of many unique elements, each of which is important and significant.",
        category: "7 - Зал",
        width: "112",
        height: "92",
        left: "339",
        top: "-44",
        imgUrl: require("@/assets/pavilions/rus-img-5.webp"),
        id: 153,
      },
      { 
        name: "Купольный зал",
        name_eng: "Dome hall",
        description: "Кульминация экскурсии по павильону. Вы окажетесь в Купольном зале у «Портала мечты». Почти 900 тысяч гостей павильона «Россия – страна возможностей» поделились своими мечтами и вдохновились на их реализацию за время работы Международной выставки-форума «Россия». В день закрытия выставки-форума капсула с заветными желаниями юных гостей была передана на космодром Байконур, а уже 15 августа 2024 года детские мечты запустили в космос – капсула отправилась на борт Международной космической станции.",
        description_eng: "The culmination of a pavilion tour.You will find yourself in the dome hall at the 'Portal of Dreams.'Almost 900 thousand guests of the Pavilion “Russia-Country of Opportunities” shared their dreams and were inspired to their implementation during the work of the international exhibition-forum “Russia”.On the day of the closing of the exhibition-forum, the capsule with the treasured desires of the young guests was transferred to the Baikonur cosmodrome, and on August 15, 2024, children's dreams launched into space-the capsule went aboard the International Space Station.",
        category: "8 - Зал",
        width: "203",
        height: "215",
        left: "122",
        top: "-126",
        imgUrl: require("@/assets/pavilions/rus-img-6.webp"),
        id: 154,
      },
    ]
  },
  {
    id: 3,
    class: "sber-fill",
    title: "Добро пожаловать в павильон «СБЕР»",
    mapImg: require("@/assets/pavilions/Sber.webp"),
    elements: [
      {
        name: "Исторический офис",
        name_eng: "Historical office",
        description: "Экспозиция Сбер размещается в павильоне ВДНХ №2, который построен в 1954 году. Чтобы отдать дань уважения пространству, в котором размещена выставка, в первом зале представлен исторический облик отделения СберБанка в середине прошлого века.  Здесь можно увидеть тщательно воссозданный интерьер из прошлого, посмотреть уникальные архивные фотографии и первый логотип банка, рассмотреть старинный кассовый аппарат, сберкнижки, монеты, ордена и другие экспонаты XIX-XX вв. Кроме того, в первом зале можно узнать больше о первом клиенте банка и его вкладе  в развитие финансовых технологий и благосостояния общества – Николае Антоновиче Кристофари, – именно его памятник установлен в историческом офисе. ",
        description_eng: "Sberbank exposition is located in the VDNH Pavilion No. 2, which was built in 1954.In order to pay tribute to the space in which the exhibition is located, the historical appearance of the Sberbank branch in the middle of the last century is represented in the first hall.Here you can see a carefully recreated interior from the past, see the unique archival photos and the first bank logo, consider the old cash register, savings bank, coins, orders and other exhibits of the XIX-XX centuries.In addition, in the first hall you can learn more about the first client of the bank and its contribution to the development of financial technologies and the well -being of society - Nikolai Antonovich Christophy - it is its monument that was erected in the historical office. ",
        category: "1 - Зал",
        width: "98",
        height: "82",
        left: "324",
        top: "78",
        imgUrl: require("@/assets/pavilions/sber-img-1.webp"),
        id: 118,
      },
      {
        name: "Прешоу",
        name_eng: "Prešo",
        description: "В этом зале вы сможете погрузиться в историю создания и развития СберБанка, а также с помощью виртуального лифта увидеть знаковые события в этом процессе.  История СберБанка начинается в 1841 году – тогда император Николай I повелел открыть сберегательные кассы, первая из которых располагалась в одном небольшом помещении с деревянными счётами и бумажными книгами, где работали всего 7 человек, – так началась история банка. Сегодня Сбер насчитывает тысячи офисов и отделений по всей России. Узнайте, как менялись люди и технологии, как менялась наша страна, а вместе с ней и Сбер.",
        description_eng: "In this hall you can plunge into the history of the creation and development of Sberbank, as well as with the help of a virtual elevator to see iconic events in this process.The story of Sberbank began in 1841 - then Emperor Nicholas I ordered the opening savings box office, the first of which was located in one small room with wooden accounts and paper books where only 7 people worked - this is how the history of the bank began.Today, Sber has thousands of offices and departments throughout Russia.Find out how people and technologies have changed, how our country changed, and with it Sberus.",
        category: "2 - Зал",
        width: "172",
        height: "158",
        left: "188",
        top: "-83",
        imgUrl: require("@/assets/pavilions/sber-img-2.webp"),
        id: 119,
      },
      {
        name: "Современный офис",
        name_eng: "Modern office",
        description: "Мы переносимся в современный офис Сбера. Сегодня Сбер – это не просто отделение банка, где можно получить услуги: здесь все продумано для заботы о клиенте – в ожидании своей очереди можно выпить кофе, протестировать умные устройства Sber, а для детей организованы детские уголки, где они могут играть в ожидании родителей. Здесь также можно узнать больше о покупке монет из драгоценных металлов – грамотной инвестиции или памятном подарке, который позволяет хранить часть сбережений в золоте и серебре в течение многих лет.",
        description_eng: "We are transferred to the modern office of Sberbank.Today, Sberus is not just a bank branch where you can get services: everything is thought out here to take care of the client - you can drink coffee, testing Sber smart devices, and children's corners are organized, where they can play in anticipation of parents.Here you can also learn more about the purchase of precious metals - competent investment or memorable gift, which allows you to store part of the savings in gold and silver for many years.",
        category: "3 - Зал",
        width: "68",
        height: "82",
        left: "246",
        top: "-168",
        imgUrl: require("@/assets/pavilions/sber-img-4.webp"),
        id: 120,
      },
      {
        name: "Зал «Надежный Сбер»",
        name_eng: "Hall 'Reliable Sber'",
        description: "В этом зале вы узнаете больше об инструментах защиты Сбера, которые уже сегодня обеспечивают безопасность данных, а также познакомитесь с технологическими решениями, которые делают удобнее и спокойнее наши дома, улицы и города. Вы узнаете, как и для чего применяется искусственный интеллект в приложениях и отделениях банка, как распознать мошенника и обхитрить его, как настроить дополнительные функции защиты на своих устройствах.",
        description_eng: "In this hall you will learn more about the Sberbank protection tools, which today provide data security, as well as get acquainted with technological solutions that make our homes, streets and city more conveniently and calmer.You will learn how and why artificial intelligence is used in applications and branches of the bank, how to recognize a fraudster and outwit it, how to configure additional protection functions on your devices.",
        category: "4 - Зал",
        width: "58",
        height: "82",
        left: "188",
        top: "-168",
        imgUrl: require("@/assets/pavilions/sber-img-3.webp"),
        id: 121,
      },
      {
        name: "Зал «Созидающий Сбер»",
        name_eng: "Hall 'Creative Sber'",
        description: "Все технологии, над которыми работает команда Сбера, существуют, чтобы делать жизнь каждого человека проще. Спланировать семейный бюджет, придумать, как сделать дом комфортнее и красивее, и даже купить новую квартиру – всё это становится легче вместе со Сбером. В этом зале вы окажетесь в пространстве жилого комплекса Сберсити и узнаете о технологиях и возможностях умного дома экосистемы Сбер, с которыми повседневная жизнь становится еще удобнее и интереснее.",
        description_eng: "All the technologies on which the Sberbank team works exist to make the life of every person easier.To plan a family budget, to figure out how to make a house more comfortable and more beautiful, and even buy a new apartment - all this becomes easier with Sberbrus.In this hall you will find yourself in the space of the residential complex of SbersTii and learn about the technologies and capabilities of the smart house of the Sberus ecosystem, with which everyday life becomes even more convenient and more interesting.",
        category: "5 - Зал",
        width: "83",
        height: "82",
        left: "101",
        top: "-168",
        imgUrl: require("@/assets/pavilions/sber-img-5.webp"),
        id: 122,
      },
      {
        name: "Зал «Развивающий Сбер»",
        name_eng: "Hall 'Developing Sber'",
        description: "Сбер верит, что образование и любознательность в любом возрасте – это одна из самых твердых валют в современном мире. И поэтому старается сделать так, чтобы и дети, и взрослые всегда могли получить знания и навыки, которые пригодятся в работе и в жизни. В этом зале вы узнаете больше о курсах Сберуниверситета и других образовательных проектах Сбера, познакомитесь с возможностями дополнительного образования и вдохновитесь историями реальных людей, которые не побоялись изменить свою жизни и начали новую карьеру. ",
        description_eng: "Sber believes that education and curiosity at any age are one of the hardest currencies in the modern world.And therefore, he tries to make sure that both children and adults can always gain knowledge and skills that are useful in work and in life.In this hall you will learn more about the courses of Sberuniversity and other educational projects of Sberbrus, get acquainted with the possibilities of additional education and are inspired by the stories of real people who were not afraid to change their lives and began a new career.",
        category: "6 - Зал",
        width: "83",
        height: "158",
        left: "101",
        top: "-83",
        imgUrl: require("@/assets/pavilions/sber-img-6.webp"),
        id: 123,
      },
      {
        name: "Зал «Детская зона и кафе»",
        name_eng: "Hall 'Children's Zone and Cafe'",
        description: "Когда весь мир – детская площадка. Этот зал полностью посвящён творчеству и классными креативными возможностями Сбера, которые будут интересны детям в возрасте от 3 до 103 лет. Здесь вы узнаете больше о возможностях метавселенной Сбера и нейронной сети по созданию изображений и видео Kandinsky, прослушаете уроки СберКота по основам финансовой грамотности, посмотрите на любимых героев «СоюзМультФильма».",
        description_eng: "When the whole world is a playground.This hall is completely dedicated to creativity and the cool creative capabilities of Sberbire, which will be interesting to children aged 3 to 103 years.Here you will learn more about the capabilities of the metavselnaya Sberbank and the Neuron network to create images and video Kandinsky, listen to the Lessons of the Sbercarticle on the basics of financial literacy, look at the favorite heroes of Soyuzmultfilm.",
        category: "7 - Зал",
        width: "97",
        height: "154",
        left: "1",
        top: "-85",
        imgUrl: require("@/assets/pavilions/sber-img-7.webp"),
        id: 124,
      },
      {
        name: "Зал «Здоровый Сбер»",
        name_eng: "Hall 'Healthy Sber'",
        description: "В этом зале можно уделить время себе и отдохнуть от ритма большого города. Здесь вы увидите, как современные технологии делают медицину доступнее для каждого, а отдых – более полезным и комфортным, познакомитесь с цифровыми сервисами заботы о здоровье от Сбера, а также сможете насладиться виртуальным путешествием по туристическим жемчужинам нашей страны от Калининграда до Владивостока.",
        description_eng: "In this hall you can devote time to yourself and take a break from the rhythm of a big city.Here you will see how modern technologies make medicine more accessible to everyone, and the rest is more useful and comfortable, get acquainted with digital services for health care from the Sberbank, and you can also enjoy the virtual trip to the tourist pearls of our country from Kaliningrad to Vladivostok.",
        category: "8 - Зал",
        width: "83",
        height: "80",
        left: "101",
        top: "79",
        imgUrl: require("@/assets/pavilions/sber-img-8.webp"),
        id: 125,
      },
      {
        name: "Зал «Увлекательный Сбер»",
        name_eng: "Hall 'Fascinating Sber'",
        description: "Сбер – это не только серьёзные технологии и банковские продукты, но ещё и развлечения! Здесь вы узнаете о том, как креативные технологии Сбера помогают раскрыть ваш творческий потенциал и сделать досуг ярче и интереснее. В этом зале вас встретит любимец детей и взрослых – огромный Чебурашка, которого так и хочется обнять. Вы познакомитесь с технологиями виртуальной и дополненной реальности, сможете перенестись в метавселенную Сбера и даже создать картины или музыку с помощью искусственного интеллекта!",
        description_eng: "Sber is not only serious technologies and banking products, but also entertainment!Here you will learn about how the creative technologies of Sberbank help to reveal your creative potential and make leisure brighter and more interesting.In this hall you will be met by a favorite of children and adults - a huge Cheburashka, who so I want to hug.You will get acquainted with the technologies of virtual and augmented reality, you can move to Sberban's metavselnaya and even create paintings or music using artificial intelligence!",
        category: "9 - Зал",
        width: "83",
        height: "80",
        left: "188",
        top: "79",
        imgUrl: require("@/assets/pavilions/sber-img-9.webp"),
        id: 126,
      },
      {
        name: "Постшоу",
        name_eng: "Postshou",
        description: "В финальном зале вы узнаете больше о главной составляющей успеха и развития Сбера – о команде! Именно люди, команда, семья – то, что вдохновляет и подталкивает к новому, помогает развивать сервисы, создавать новое и становиться еще лучше, безопаснее и удобнее. ",
        description_eng: "In the final hall you will learn more about the main component of the success and development of Sberbank - about the team!It is people, a team, a family - that inspires and pushes to a new one, helps to develop services, create new things and get even better, safer and more convenient.",
        category: "10 - Зал",
        width: "47",
        height: "80",
        left: "274",
        top: "79",
        imgUrl: require("@/assets/pavilions/sber-img-10.webp"),
        id: 127,
      },
    ]
  },
  {
    id: 4,
    class: "town-fill",
    title: "Добро пожаловать в павильон «Умный город»",
    mapImg: require("@/assets/pavilions/town.webp"),
    elements: [
      {
        name: "Зона «Ресепшн»",
        name_eng: "Reception zone",
        description: "Добро пожаловать в павильон «Умный город» на ВДНХ. Здесь вы узнаете больше о цифровых проектах Москвы и познакомитесь с уникальными сервисами, которые доступны каждому жителю столицы.",
        description_eng: "Welcome to the Pavilion 'Smart City' at VDNH.Here you will learn more about Moscow digital projects and get acquainted with unique services that are available to every resident of the capital.",
        category: "1 - Зал",
        width: "104",
        height: "44",
        left: "255",
        top: "-55",
        imgUrl: require("@/assets/pavilions/town-img-0.webp"),
        id: 130,
      },
      {
        name: "Погружение 360",
        name_eng: "Immersion 360",
        description: "Здесь начинается погружение в мир технологий Москвы. В этом зале вы сможете узнать больше о цифровой экосистеме столицы. Сегодня она объединяет более 450 электронных сервисов и услуг, помогая каждому жителю решать важные для него вопросы онлайн.",
        description_eng: "Here begins immersion in the world of technology of Moscow.In this hall you can learn more about the digital ecosystem of the capital.Today it combines more than 450 electronic services and services, helping each resident solve important issues for him online.",
        category: "2 - Зал",
        width: "56",
        height: "44",
        left: "198",
        top: "-34",
        imgUrl: require("@/assets/pavilions/town-img-1.webp"),
        id: 131,
      },
      {
        name: "Интеллектуальный транспорт",
        name_eng: "Intellectual transport",
        description: "Добро пожаловать в зону интеллектуального транспорта. Здесь вы узнаете, как развивалась на протяжении последних десяти лет транспортная система столицы, как создавались умные светофоры, откуда навигаторы узнают о пробках и авариях на дорогах и чем удобна система оплаты проезда по биометрии. Рядом с этой зоной гости павильона могут отправиться в виртуальное путешествие по знаковым столичным достопримечательностям – уникальные прогулки созданы на базе Цифрового двойника города Москвы.",
        description_eng: "Welcome to the intellectual transport zone.Here you will learn how the transport system of the capital has developed over the past ten years, how smart traffic lights have been created, from where navigators will learn about traffic jams and accidents on the roads and how convenient the system of payment for biometry is convenient.Next to this zone, the guests of the pavilion can go on a virtual journey through the iconic metropolitan attractions - unique walks are created on the basis of the digital double of the city of Moscow.",
        category: "3 - Зал",
        width: "75",
        height: "67",
        left: "179",
        top: "10",
        imgUrl: require("@/assets/pavilions/town-img-2.webp"),
        id: 132,
      },
      {
        name: "Цифровое здравоохранение",
        name_eng: "Digital healthcare",
        description: "В этой зоне вы узнаете о применении искусственного интеллекта в столичной системе здравоохранения и онлайн-возможностях для пациентов и врачей, увидите, как работают ЕМИАС и других проекты в сфере здравоохранения. Здесь же вы познакомитесь с возможностями электронной медицинской карты: в ней видны результаты анализов, протоколы осмотров, история вакцинации и диспансеризации, выписанные действующие рецепты, больничные листы и многие другие сведения о здоровье.",
        description_eng: "In this zone, you will learn about the use of artificial intelligence in the capital's healthcare system and online capabilities for patients and doctors, you will see how Emias and other healthcare projects work.Here you will get acquainted with the capabilities of the electronic medical card: it can be seen in the test results, inspection protocols, the history of vaccination and medical examination, the current recipes, sick leave and many other health information.",
        category: "4 - Зал",
       width: "75",
        height: "67",
        left: "6",
        top: "10",
        imgUrl: require("@/assets/pavilions/town-img-3.webp"),
        id: 133,
      },
      {
        name: "Благоустройство и комфорт",
        name_eng: "Improvement and comfort",
        description: "Здесь вы узнаете о сервисах и ИТ-проектах Москвы, которые помогают заботиться об экологии города, влиять на то, каким он будет в будущем. Например, с помощью сервиса «Вывоз ненужных вещей» можно отправить на переработку старую мебель, сломанную бытовую технику или оставшиеся после ремонта чугунные ванны и двери. А проект «Город заданий» дает каждому жителю участвовать в жизни города и помогать городским службам: выполняя простые задания, можно сделать столицу еще чище и удобнее, протестировать новые сервисы и многое другое.",
        description_eng: "Here you will learn about the services and IT projects of Moscow, which help to take care of the ecology of the city, to influence what it will be in the future.For example, with the help of the “export of unnecessary things” service, you can send old furniture, broken household appliances or cast -iron baths and doors that remained after repair.And the project “City of Tasks” gives each resident to participate in the life of the city and help city services: by completing simple tasks, you can make the capital even cleaner and more convenient, testing new services and much more.",
        category: "5 - Зал",
       width: "75",
        height: "74",
        left: "6",
        top: "-64",
        imgUrl: require("@/assets/pavilions/town-img-4.webp"),
        id: 134,
      },
      {
        name: "Москва – больше, чем город",
        name_eng: "Moscow - more than the city",
        description: "В этом пространстве вы узнаете о сервисах в сфере культуры и туризма, с помощью которых жизнь в мегаполисе становится еще ярче и интереснее. Например, сервис «Библиотеки Москвы» на mos.ru дает возможность выбрать и забронировать нужную книгу в библиотеке. А с помощью сервиса аренды городских пространств «Вместе с культурой» любой горожанин может арендовать помещение в учреждении культуры, чтобы провести там свою лекцию, концерт или мастер-класс. ",
        description_eng: "In this space, you will learn about services in the field of culture and tourism, with the help of which life in a metropolis becomes even brighter and more interesting.For example, the Moscow Library Service on Mos.ru makes it possible to choose and book the desired book in the library.And with the help of the rental service of urban spaces “together with culture”, any citizen can rent a premises in an institution of culture in order to give his lecture, concert or master class there.",
        category: "6 - Зал",
       width: "95",
        height: "35",
        left: "81",
        top: "-64",
        imgUrl: require("@/assets/pavilions/town-img-5.webp"),
        id: 135,
      },
            {
        name: "Центр управления городом ",
        name_eng: "City management center",
        description: "Благодаря этой зоне экспозиции вы познакомитесь с технологичными проектами, которые помогают управлять столицей, обеспечивают безопасность в городе, а также открывают широкие возможности для предпринимателей. Вы узнаете, для чего городу Цифровой двойник и как виртуальная копия Москвы помогает при планировании строительства, как система видеонаблюдения помогает обеспечивать чистоту и порядок в мегаполисе, а также посмотрите на возможности Инвестиционного портала, где собраны различные сервисы для предпринимателей и инвесторов, представлена недвижимость напрямую от города для открытия или расширения бизнеса в столице. ",
        description_eng: "Thanks to this exposition zone, you will get acquainted with technological projects that help manage the capital, ensure security in the city, and also open up wide opportunities for entrepreneurs.You will find out why the city is a digital double and how a virtual copy of Moscow helps when planning construction, how a video surveillance system helps to ensure cleanliness and order in a metropolis, and also look at the capabilities of the investment portal, which collects various services for entrepreneurs and investors, present real estate directly from the city for opening or expanding the business in the capital.",
        category: "7 - Зал",
       width: "95",
        height: "90",
        left: "81",
        top: "-24",
        imgUrl: require("@/assets/pavilions/town-img-6.webp"),
        id: 136,
      },
            {
        name: "Зона Образования",
        name_eng: "The zone of education",
        description: "«Московская электронная школа» («МЭШ») — это уникальная цифровая платформа для столичных школьников и их родителей. В зоне «Образование» вы узнаете, как благодаря сервисам «МЭШ» дети, родители и учителя забыли про меловые доски, бумажные дневники и журналы, которые можно потерять, познакомитесь с возможностями сервисов «Москвёнок», «Портфолио учащегося» и «Электронный дневник». ",
        description_eng: "“Moscow Electronic School” (“Mash”) is a unique digital platform for capital schoolchildren and their parents.In the “Education” zone, you will learn how, thanks to Mash services, children, parents and teachers forgot about chalk boards, paper diaries and magazines that you can lose, get acquainted with the capabilities of the Moskovok, Portfolio of the Student and Electronic Diary.",
        category: "8 - Зал",
       width: "22",
        height: "34",
        left: "176",
        top: "-24",
        imgUrl: require("@/assets/pavilions/town-img-7.webp"),
        id: 137,
      },
            {
        name: "Выставка «Цифровые технологии Москвы. К 30-летию Рунета»",
        name_eng: "Exhibition 'Digital Technologies of Moscow.To the 30th anniversary of Runet '",
        description: "Здесь вы узнаете, как на протяжении трех десятилетий Москва создавала и развивала уникальные цифровые проекты. В формате интерактивной ленты времени вы сможете узнать, как в Москве с 1994 года развивались технологии, платформы и сервисы, как они применялись на благо города и какую важную роль играют для жителей и столицы сегодня. А зона «Сферы жизни Москвы» рассказывает, как цифровизация изменила в лучшую сторону шесть столичных отраслей.",
        description_eng: "Here you will learn how for three decades Moscow created and developed unique digital projects.In the format of the interactive tape of time, you can find out how technologies, platforms and services have developed in Moscow since 1994, how they were used for the good of the city and what important role they play for residents and capital today.And the Zone 'Spheres of the Life of Moscow' tells how digitalization has changed six metropolitan industries for the better.",
        category: "9 - Зал",
       width: "78",
        height: "30",
        left: "176",
        top: "-64",
        imgUrl: require("@/assets/pavilions/town-img-8.webp"),
        id: 138,
      },
            {
        name: "5G Демо-центр",
        name_eng: "5G Demo center",
        description: "В этом пространстве павильоне представлено оборудование для тестирования проектов, разработанных на базе сетей связи 5G. Здесь также расположены рабочие места для резидентов 5G Демо-центра: стартапов, компаний-разработчиков, научно-исследовательских организаций. ",
        description_eng: "In this space, the pavilion is presented equipment for testing projects developed on the basis of 5G communication networks.There are also jobs for residents of the 5G demo center: startups, developing companies, research organizations. ",
        category: "10 - Зал",
        width: "103",
        height: "131",
        left: "362",
        top: "-55",
        imgUrl: require("@/assets/pavilions/town-img-9.webp"),
        id: 139,
      },
    ]
  },
  {
    id: 5,
    class: "web-fill",
    title: "Добро пожаловать в павильон «ВЭБ РФ»",
    mapImg: require("@/assets/pavilions/Web.webp"),
    elements: [
      {
        name: "ЗАЛ С МАШИНОЙ ВРЕМЕНИ",
        name_eng: "Hall with a time machine ",
        description: "Добро пожаловать в павильон «ВЭБ.РФ. Города для жизни сбываются!». Его экспозиция показывает, как менялись российские города и ключевые для их жителей сферы: медицина, образование, транспорт и ЖКХ. В павильоне вы также узнаете, как проекты ВЭБ.РФ помогают развивать города по всей России и повышают качество жизни миллионов людей.",
        description_eng: "Welcome to the pavilion 'Web.rf.Cities come true for life!'. His exposition shows how Russian cities and key areas have changed for their residents: medicine, education, transport and housing and communal services.In the pavilion, you will also learn how VEB.RF projects help to develop cities throughout Russia and increase the quality of life of millions of people.",
        category: "1 - Зал",
        width: "90",
        height: "150",
        left: "368",
        top: "-66",
        imgUrl: require("@/assets/pavilions/web-img-1.webp"),
        id: 128,
      },
      {
        name: "ЗАЛ 2",
        name_eng: "Hall 2",
        description: "В этом зале вы узнаете, как изменилась жизнь россиян за последние 20 лет. Большая часть населения России – почти 110 миллионов человек – живет в городах. Сейчас в них есть возможности для самореализации, учебы, работы или развития бизнеса. Вы сможете познакомиться с новыми технологическими решениями в здравоохранении, проектами университетских кампусов и современных школ, с новыми туристическими возможностями, а еще узнаете о прорывных достижениях российских ученых, как создаются лекарства и о самых-самых городах нашей страны.",
        description_eng: "In this hall you will learn how the life of Russians has changed over the past 20 years.Most of the Russian population - almost 110 million people - live in cities.Now they have opportunities for self -realization, study, work or business development.You can get acquainted with new technological solutions in healthcare, projects of university campuses and modern schools, with new tourist capabilities, and you will also learn about the breakthrough achievements of Russian scientists, how medicines are created about the most-most cities of our country.",
        category: "2 - Зал",
        width: "250",
        height: "89",
        left: "110",
        top: "-36",
        imgUrl: require("@/assets/pavilions/web-img-2.webp"),
        id: 129,
      },
      
    ]
  },
  {
    id: 6,
    class: "finance-fill",
    title: "Добро пожаловать в павильон «УМНЫЕ ФИНАНСЫ»",
    mapImg: require("@/assets/pavilions/Fin.webp"),
    elements: [
      {
        name: "Фойе павильона",
        name_eng: "Foyer of the pavilion",
        description: "Вы оказались в павильоне «Умные финансы» Банка России. Здесь вы узнаете об эволюции технологий и сервисов финансового рынка. В залах павильона вы сможете перенестись в прошлое, узнаете о современных возможностях в сфере платежей, переводов и других услуг, а также заглянете в будущее, чтобы увидеть, каким станет мир финансов через несколько лет.",
        description_eng: "You found yourself in the Smart Finance pavilion of the Bank of Russia.Here you will learn about the evolution of technology and financial market services.In the halls of the pavilion, you can transfer to the past, learn about the modern possibilities in the field of payments, transfers and other services, and also look into the future to see what the world of finances will become in a few years.",
        category: "1 - Зал",
        width: "145",
        height: "143",
        left: "163",
        top: "112",
        imgUrl: require("@/assets/pavilions/fin-img-1.webp"),
        id: 140,
      },
      {
        name: "Зал «Прошлое»",
        name_eng: "The hall 'Past'",
        description: "Добро пожаловать в доцифровую эпоху! В этом зале вы сможете оказаться в прошлом и узнать, как жили люди всего 15-20 лет назад: увидите интерьер среднестатистической квартиры с чешской стенкой и пузатым телевизором с кинескопным экраном, узнаете, что раньше купить воду в автомате можно было за 1 копейку, в автобусах для проезда компостировали бумажные билетики для проезда, а для перевода денег другому человеку приходили в сберкассу. ",
        description_eng: "Welcome to the Docifian era!In this hall you can be in the past and find out how people lived only 15-20 years ago: you will see the interior of an average apartment with a Czech wall and a pot-bellied TV with a kinescopa screen, you will find out that it was possible to buy water in the machine for 1 kopecks, paper tickets were composted for travel, and they came to another person to transfer money to another personSberkassu.",
        category: "2 - Зал",
     width: "145",
        height: "249",
        left: "12",
        top: "-33",
        imgUrl: require("@/assets/pavilions/fin-img-2.webp"),
        id: 141,
      },
      {
        name: "Перед переходом в зал «настоящее»",
        name_eng: "Before moving to the 'Present' hall",
        description: "Пройдите через турникеты современного метро и окажитесь в настоящем! С появлением финансовых технологий мы перешли на новый уровень удобства и безопасности в области денежных операций. Благодаря широкому распространению мобильных устройств и доступности интернета мы можем быстро и легко проводить платежи, получать кредиты и инвестировать в акции. А еще в этом зале вы сможете узнать больше о современных возможностях оплаты проезда в общественном транспорте по биометрии и посмотреть, как выглядели бумажные рубли разных эпох.",
        description_eng: "Go through the turnstiles of the modern metro and find yourself in the present!With the advent of financial technology, we switched to a new level of convenience and security in the field of monetary operations.Due to the wide distribution of mobile devices and the availability of the Internet, we can quickly and easily make payments, receive loans and invest in shares.And in this room you can learn more about the modern possibilities of paying for public transport on biometrics and see how paper rubles looked like.",
        category: "3 - Зал",
        width: "36",
        height: "147",
        left: "122",
        top: "-184",
        imgUrl: require("@/assets/pavilions/fin-img-3.webp"),
        id: 142,
      },
      {
        name: "Зал «квартира»",
        name_eng: "Hall «apartment»",
        description: "Вы перенесетесь в интерьер современной квартиры, чтобы узнать о том, какие банковские и финансовые услуги можно получить не выходя из дома. Вас встретит умная колонка, с помощью которой можно оплачивать услуги без дополнительных хлопот. А благодаря банковским мобильным приложениям можно перевести деньги между своими счетами или другому человеку, пополнить баланс мобильного телефона, оплатить покупки, оформить кредит и многое другое.  ",
        description_eng: "You will move to the interior of a modern apartment to find out about which banking and financial services you can get without leaving your home.You will be met by a smart column with which you can pay for services without additional trouble.And thanks to banking mobile applications, you can transfer money between your accounts or another person, replenish the balance of a mobile phone, pay for purchases, make a loan and much more. ",
        category: "4 - Зал",
   width: "110",
        height: "147",
        left: "12",
        top: "-184",
        imgUrl: require("@/assets/pavilions/fin-img-5.webp"),
        id: 143,
      },
      {
        name: "«Кафе»",
        name_eng: "'Cafe'",
        description: "В этом зале вы узнаете, как изменился подход к организации и оформлению банковских пространств, которые сегодня больше напоминают уютное кафе, чем офис. Современные отделения банков отличаются комфортной планировкой помещений с учетом потребностей клиентов и сотрудников, наличием интерактивных информационных панелей, возможностью оперативного решения вопросов и получения полного спектра банковских услуг.  ",
        description_eng: "In this hall you will learn how the approach to organizing and registering banking spaces, which today more resemble a cozy cafe, has changed than an office.Modern branches of banks are distinguished by a comfortable layout of the premises taking into account the needs of customers and employees, the availability of interactive information panels, the possibility of prompt resolving issues and obtaining a full range of banking services. ",
        category: "5 - Зал",
        width: "145",
        height: "147",
        left: "162",
        top: "-184",
        imgUrl: require("@/assets/pavilions/fin-img-4.webp"),
        id: 144,
      },
      {
        name: "Зал «Инвестиции»",
        naMe_eng: "Hall 'Investments'",
        description: "Мы находимся в зале розничных инвестиций. Здесь в лаундж-зоне представлена библиотека инвестора.",
        descripTion_eng: "We are in the retail hall. Here the investor library is presented in the lunge zone.",
        category: "6 - Зал",
        width: "145",
        height: "146",
        left: "313",
        top: "-185",
        imgUrl: require("@/assets/pavilions/fin-img-6.webp"),
        id: 145,
      },

      {
        name: "«Будущее»",
        name_eng: "Before the hall'Future'",
        description: "Добро пожаловать в новый мир! В этом зале вы узнаете больше о технологиях, которые могут стать реальностью уже завтра: о голографических экранах вместо персональных телефонов и компьютеров, умных устройствах оценки здоровья, возможностях виртуальных ассистентов, автопилотируемых такси, цифровом гардеробе, персонализированных цифровых сервисах и многих других опциях будущего, благодаря которым жизнь станет еще удобнее, комфортнее  и ярче. ",
        description_eng: "Welcome to the New World!In this hall you will learn more about technologies that can become a reality tomorrow: about holographic screens instead of personal phones and computers, smart devices for assessing health, the possibilities of virtual assistants, autopiloted taxis, digital wardrobe, personalized digital services and many other options for the future, thanks to which life will become even more convenient, comfortable and brighter.",
        category: "7 - Зал",
        width: "147",
        height: "144",
        left: "161",
        top: "-34",
        imgUrl: require("@/assets/pavilions/fin-img-7.webp"),
        id: 146,
      },

    ]
  },
]