export const DRESS_ID = 28;
export const EMPTY_LEGS = 13;
export const WOMEN_DEFAULT_TORSO = [23, 10, 1];

export const characterModels = {
    face: {
        SkinHead: [
            {
                index: 0,
                path: require("@/assets/models/Man_Head/Male_Head_id_0.jpg"),
                sex: 0,
            },
            {
                index: 10,
                path: require("@/assets/models/Girl_Head/Female_Head_id_0.jpg"),
                sex: 1,
            },
            {
                index: 1,
                path: require("@/assets/models/Man_Head/Male_Head_id_1.jpg"),
                sex: 0,
            },
            {
                index: 11,
                path: require("@/assets/models/Girl_Head/Female_Head_id_1.jpg"),
                sex: 1,
            },
            {
                index: 2,
                path: require("@/assets/models/Man_Head/Male_Head_id_2.jpg"),
                sex: 0,
            },
            {
                index: 12,
                path: require("@/assets/models/Girl_Head/Female_Head_id_2.jpg"),
                sex: 1,
            },
            {
                index: 3,
                path: require("@/assets/models/Man_Head/Male_Head_id_3.jpg"),
                sex: 0,
            },
            {
                index: 13,
                path: require("@/assets/models/Girl_Head/Female_Head_id_3.jpg"),
                sex: 1,
            },
            {
                index: 4,
                path: require("@/assets/models/Man_Head/Male_Head_id_4.jpg"),
                sex: 0,
            },
            {
                index: 14,
                path: require("@/assets/models/Girl_Head/Female_Head_id_4.jpg"),
                sex: 1,
            },
            {
                index: 5,
                path: require("@/assets/models/Man_Head/Male_Head_id_5.jpg"),
                sex: 0,
            },
            {
                index: 15,
                path: require("@/assets/models/Girl_Head/Female_Head_id_5.jpg"),
                sex: 1,
            },
            {
                index: 6,
                path: require("@/assets/models/Man_Head/Male_Head_id_6.jpg"),
                sex: 0,
            },
            {
                index: 16,
                path: require("@/assets/models/Girl_Head/Female_Head_id_6.jpg"),
                sex: 1,
            },
            {
                index: 7,
                path: require("@/assets/models/Man_Head/Male_Head_id_7.jpg"),
                sex: 0,
            },
            {
                index: 17,
                path: require("@/assets/models/Girl_Head/Female_Head_id_7.jpg"),
                sex: 1,
            },
            {
                index: 8,
                path: require("@/assets/models/Man_Head/Male_Head_id_8.jpg"),
                sex: 0,
            },
            {
                index: 18,
                path: require("@/assets/models/Girl_Head/Female_Head_id_8.jpg"),
                sex: 1,
            },
            {
                index: 9,
                path: require("@/assets/models/Man_Head/Male_Head_id_9.jpg"),
                sex: 0,
            },
            {
                index: 19,
                path: require("@/assets/models/Girl_Head/Female_Head_id_9.jpg"),
                sex: 1,
            },
            {
                index: 20,
                path: require("@/assets/models/Man_Head/Male_Head_id_10.jpg"),
                sex: 0,
            },
        ],
        SkinHair: [
            {
                index: 0,
                path: require("@/assets/models/Man_Hairs/Male_Hair_id_0.jpg"),
                sex: 0,
                materials: [{
                    name: 'SkinHair',
                    index: 1
                }]
            },
            {
                index: 0,
                path: require("@/assets/models/Girl_Hairs/Female_Hair_id_0.jpg"),
                sex: 1,
                materials: [{
                    name: 'SkinHair',
                    index: 1
                }]
            },
            {
                index: 1,
                path: require("@/assets/models/Man_Hairs/Male_Hair_id_1.jpg"),
                sex: 0,
                materials: [{
                    name: 'SkinHair',
                    index: 1
                }]
            },
            {
                index: 1,
                path: require("@/assets/models/Girl_Hairs/Female_Hair_id_1.jpg"),
                sex: 1,
                materials: [{
                    name: 'SkinHair',
                    index: 1
                }]
            },
            {
                index: 2,
                path: require("@/assets/models/Man_Hairs/Male_Hair_id_2.jpg"),
                sex: 0,
                materials: [{
                    name: 'SkinHair',
                    index: 1
                }]
            },
            {
                index: 2,
                path: require("@/assets/models/Girl_Hairs/Female_Hair_id_2.jpg"),
                sex: 1,
                materials: [{
                    name: 'SkinHair',
                    index: 1
                }]
            },
            {
                index: 3,
                path: require("@/assets/models/Man_Hairs/Male_Hair_id_3.jpg"),
                sex: 0,
                materials: [{
                    name: 'SkinHair',
                    index: 1
                }]
            },
            {
                index: 3,
                path: require("@/assets/models/Girl_Hairs/Female_Hair_id_3.jpg"),
                sex: 1,
                materials: [{
                    name: 'SkinHair',
                    index: 1
                }]
            },
            {
                index: 4,
                path: require("@/assets/models/Man_Hairs/Male_Hair_id_4.jpg"),
                sex: 0,
                materials: [{
                    name: 'SkinHair',
                    index: 1
                }]
            },
            {
                index: 4,
                path: require("@/assets/models/Girl_Hairs/Female_Hair_id_4.jpg"),
                sex: 1,
                materials: [{
                    name: 'SkinHair',
                    index: 1
                }]
            },
            {
                index: 5,
                path: require("@/assets/models/Man_Hairs/Male_Hair_id_5.jpg"),
                sex: 0,
                materials: [{
                    name: 'SkinHair',
                    index: 1
                }]
            },
            {
                index: 5,
                path: require("@/assets/models/Girl_Hairs/Female_Hair_id_5.jpg"),
                sex: 1,
                materials: [{
                    name: 'SkinHair',
                    index: 1
                }]
            },
            {
                index: 6,
                path: require("@/assets/models/Man_Hairs/Male_Hair_id_6.jpg"),
                sex: 0,
                materials: [{
                    name: 'SkinHair',
                    index: 1
                }]
            },
            {
                index: 6,
                path: require("@/assets/models/Girl_Hairs/Female_Hair_id_6.jpg"),
                sex: 1,
                materials: [{
                    name: 'SkinHair',
                    index: 1
                }]
            },
            {
                index: 7,
                path: require("@/assets/models/Man_Hairs/Male_Hair_id_7.jpg"),
                sex: 0,
                materials: [{
                    name: 'SkinHair',
                    index: 1
                }]
            },
            {
                index: 7,
                path: require("@/assets/models/Girl_Hairs/Female_Hair_id_7.jpg"),
                sex: 1,
                materials: [{
                    name: 'SkinHair',
                    index: 1
                }]
            },
            {
                index: 8,
                path: require("@/assets/models/Man_Hairs/Male_Hair_id_8.jpg"),
                sex: 0,
                materials: [{
                    name: 'SkinHair',
                    index: 1
                }]
            },
            {
                index: 8,
                path: require("@/assets/models/Girl_Hairs/Female_Hair_id_8.jpg"),
                sex: 1,
                materials: [{
                    name: 'SkinHair',
                    index: 1
                }]
            },
            {
                index: 9,
                path: require("@/assets/models/Man_Hairs/Male_Hair_id_9.jpg"),
                sex: 0,
                materials: [{
                    name: 'SkinHair',
                    index: 1
                }]
            },
            {
                index: 9,
                path: require("@/assets/models/Girl_Hairs/Female_Hair_id_9.jpg"),
                sex: 1,
                materials: [{
                    name: 'SkinHair',
                    index: 1
                }]
            },
            {
                index: 10,
                path: require("@/assets/models/Man_Hairs/Male_Hair_id_10.jpg"),
                sex: 0,
                materials: [{
                    name: 'SkinHair',
                    index: 1
                }]
            },
            {
                index: 10,
                path: require("@/assets/models/Girl_Hairs/Female_Hair_id_11.jpg"),
                sex: 1,
                materials: [{
                    name: 'SkinHair',
                    index: 1
                }]
            },
            {
                index: 11,
                path: require("@/assets/models/Girl_Hairs/Female_Hair_id_12.jpg"),
                sex: 1,
                materials: [{
                    name: 'SkinHair',
                    index: 1
                }]
            },
            {
                index: 12,
                path: require("@/assets/models/Girl_Hairs/Female_Hair_id_13.jpg"),
                sex: 1,
                materials: [{
                    name: 'SkinHair',
                    index: 1
                }]
            },
            {
                index: 13,
                path: require("@/assets/models/Girl_Hairs/Female_Hair_id_14.jpg"),
                sex: 1,
                materials: [{
                    name: 'SkinHair',
                    index: 1
                }]
            },
            {
                index: 14,
                path: require("@/assets/models/Girl_Hairs/Female_Hair_id_15.jpg"),
                sex: 1,
                materials: [{
                    name: 'SkinHair',
                    index: 1
                }]
            },
            {
                index: 15,
                path: require("@/assets/models/Girl_Hairs/Female_Hair_id_16.jpg"),
                sex: 1,
                materials: [{
                    name: 'SkinHair',
                    index: 1
                }]
            },
            {
                index: 16,
                path: require("@/assets/models/Girl_Hairs/Female_Hair_id_17.jpg"),
                sex: 1,
                materials: [{
                    name: 'SkinHair',
                    index: 1
                }]
            },
            {
                index: 17,
                path: require("@/assets/models/Girl_Hairs/Female_Hair_id_18.jpg"),
                sex: 1,
                materials: [{
                    name: 'SkinHair',
                    index: 1
                }]
            },
        ],
        SkinMustache: [
            {
                index: 0,
                path: require("@/assets/models/Usi/Usi_id_0.jpg"),
                sex: 0,
                materials: [{
                    name: 'SkinMustache',
                    index: 1
                }]
            },
            {
                index: 1,
                path: require("@/assets/models/Usi/Usi_id_1.jpg"),
                sex: 0,
                materials: [{
                    name: 'SkinMustache',
                    index: 1
                }]
            },
            {
                index: 2,
                path: require("@/assets/models/Usi/Usi_id_2.jpg"),
                sex: 0,
                materials: [{
                    name: 'SkinMustache',
                    index: 1
                }]
            },
            {
                index: 3,
                path: require("@/assets/models/Usi/Usi_id_3.jpg"),
                sex: 0,
                materials: [{
                    name: 'SkinMustache',
                    index: 1
                }]
            },
            {
                index: 4,
                path: require("@/assets/models/Usi/Usi_id_4.jpg"),
                sex: 0,
                materials: [{
                    name: 'SkinMustache',
                    index: 1
                }]
            },
            {
                index: 5,
                path: require("@/assets/models/Usi/Usi_id_5.jpg"),
                sex: 0,
                materials: [{
                    name: 'SkinMustache',
                    index: 1
                }]
            },
            {
                index: 6,
                path: require("@/assets/models/Usi/Usi_id_6.jpg"),
                sex: 0,
                materials: [{
                    name: 'SkinMustache',
                    index: 1
                }]
            },
            {
                index: 7,
                path: require("@/assets/models/Usi/Usi_id_7.jpg"),
                sex: 0,
                materials: [{
                    name: 'SkinMustache',
                    index: 1
                }]
            },
            {
                index: 8,
                path: require("@/assets/models/Usi/Usi_id_8.jpg"),
                sex: 0,
                materials: [{
                    name: 'SkinMustache',
                    index: 1
                }]
            },
            {
                index: 9,
                path: require("@/assets/models/Usi/Usi_id_9.jpg"),
                sex: 0,
                materials: [{
                    name: 'SkinMustache',
                    index: 1
                }]
            },
            {
                index: 10,
                path: require("@/assets/models/Usi/Usi_id_10.jpg"),
                sex: 0,
                materials: [{
                    name: 'SkinMustache',
                    index: 1
                }]
            },
        ],
        SkinBeard: [
            {
                index: 0,
                path: require("@/assets/models/Boroda/Boroda_id_0.jpg"),
                sex: 0,
                materials: [{
                    name: 'SkinBeard',
                    index: 1
                }]
            },
            {
                index: 1,
                path: require("@/assets/models/Boroda/Boroda_id_1.jpg"),
                sex: 0,
                materials: [{
                    name: 'SkinBeard',
                    index: 1
                }]
            },
            {
                index: 2,
                path: require("@/assets/models/Boroda/Boroda_id_2.jpg"),
                sex: 0,
                materials: [{
                    name: 'SkinBeard',
                    index: 1
                }]
            },
            {
                index: 3,
                path: require("@/assets/models/Boroda/Boroda_id_3.jpg"),
                sex: 0,
                materials: [{
                    name: 'SkinBeard',
                    index: 1
                }]
            },
            {
                index: 4,
                path: require("@/assets/models/Boroda/Boroda_id_4.jpg"),
                sex: 0,
                materials: [{
                    name: 'SkinBeard',
                    index: 1
                }]
            },
            {
                index: 5,
                path: require("@/assets/models/Boroda/Boroda_id_5.jpg"),
                sex: 0,
                materials: [{
                    name: 'SkinBeard',
                    index: 1
                }]
            },
            {
                index: 6,
                path: require("@/assets/models/Boroda/Boroda_id_6.jpg"),
                sex: 0,
                materials: [{
                    name: 'SkinBeard',
                    index: 1
                }]
            },
            {
                index: 7,
                path: require("@/assets/models/Boroda/Boroda_id_7.jpg"),
                sex: 0,
                materials: [{
                    name: 'SkinBeard',
                    index: 1
                }]
            },
            {
                index: 8,
                path: require("@/assets/models/Boroda/Boroda_id_8.jpg"),
                sex: 0,
                materials: [{
                    name: 'SkinBeard',
                    index: 1
                }]
            },
            {
                index: 9,
                path: require("@/assets/models/Boroda/Boroda_id_9.jpg"),
                sex: 0,
                materials: [{
                    name: 'SkinBeard',
                    index: 1
                }]
            },
        ],
        eyebrow: [
            {
                index: 1,
                path: require("@/assets/models/Eyebrow/Brovi_1.jpg"),
                sex: 1,
            },
            {
                index: 2,
                path: require("@/assets/models/Eyebrow/Brovi_2.jpg"),
                sex: 1,
            },
            {
                index: 3,
                path: require("@/assets/models/Eyebrow/Brovi_3.jpg"),
                sex: 1,
            },
            {
                index: 4,
                path: require("@/assets/models/Eyebrow/Brovi_4.jpg"),
                sex: 1,
            },
            {
                index: 5,
                path: require("@/assets/models/Eyebrow/Brovi_5.jpg"),
                sex: 1,
            },
            {
                index: 6,
                path: require("@/assets/models/Eyebrow/Brovi_6.jpg"),
                sex: 1,
            },
        ]
    },
    body: {
        SkinTorso: [
            {
                index: 0,
                path: require('@/assets/models/Man_Torso/Man_renders.0004.jpg'),
                sex: 0,
                materials: [{
                    name: 'tShirt',
                    index: 2
                }]
            },
            {
                index: 1,
                path: require('@/assets/models/Man_Torso/Man_renders.0009.jpg'),
                sex: 0,
                materials: [{
                    name: 'sweater',
                    index: 2
                }]

            },
            {
                index: 2,
                path: require('@/assets/models/Man_Torso/Man_renders.0014.jpg'),
                sex: 0,
                materials: [{
                    name: 'sweater',
                    index: 2
                }]
            },
            {
                index: 3,
                path: require("@/assets/models/Man_Torso/Man_renders.0019.jpg"),
                sex: 0,
                materials: [{
                    name: 'turtleneck',
                    index: 2
                }]
            },
            {
                index: 4,
                path: require("@/assets/models/Man_Torso/Man_renders.0024.jpg"),
                sex: 0,
                materials: [{
                    name: 'openShirt',
                    index: 2
                }]
            },
            {
                index: 5,
                path: require("@/assets/models/Man_Torso/Man_renders.0029.jpg"),
                sex: 0,
                materials: [{
                    name: 'closedShirt',
                    index: 2
                }]
            },
            {
                index: 6,
                path: require("@/assets/models/Man_Torso/Man_renders.0034.jpg"),
                sex: 0,
                materials: [{
                    name: 'tie',
                    index: 3
                }, {
                    name: 'closedShirt',
                    index: 2
                }]
            },
            {
                index: 7,
                path: require("@/assets/models/Man_Torso/Man_renders.0039.jpg"),
                sex: 0,
                materials: [{
                    name: 'vest',
                    index: 4
                }, {
                    name: 'tie',
                    index: 3
                }, {
                    name: 'closedShirt',
                    index: 2
                }]
            },
            {
                index: 8,
                path: require("@/assets/models/Man_Torso/Man_renders.0044.jpg"),
                sex: 0,
                materials: [{
                    name: 'coat',
                    index: 3
                }, {
                    name: 'closedShirt',
                    index: 2
                }]
            },
            {
                index: 9,
                path: require("@/assets/models/Man_Torso/Man_renders.0049.jpg"),
                sex: 0,
                materials: [{
                    name: 'openShirt',
                    index: 2
                }, {
                    name: 'coat',
                    index: 3
                }]
            },
            {
                index: 10,
                path: require("@/assets/models/Man_Torso/Man_renders.0054.jpg"),
                sex: 0,
                materials: [{
                    name: 'openShirt',
                    index: 3
                }, {
                    name: 'tie',
                    index: 4
                }, {
                    name: 'vest',
                    index: 5
                }, {
                    name: 'coat',
                    index: 2
                }]
            },
            {
                index: 11,
                path: require("@/assets/models/Man_Torso/Man_renders.0059.jpg"),
                sex: 0,
                materials: [{
                    name: 'openShirt',
                    index: 3
                }, {
                    name: 'tie',
                    index: 4
                }, {
                    name: 'coat',
                    index: 2
                }]
            },
            {
                index: 12,
                path: require("@/assets/models/Man_Torso/Man_renders.0064.jpg"),
                sex: 0,
                materials: [{
                    name: 'jacket',
                    index: 3
                }, {
                    name: 'coat',
                    index: 2
                }]
            },
            {
                index: 13,
                path: require("@/assets/models/Man_Torso/Man_renders.0069.jpg"),
                sex: 0,
                materials: [{
                    name: 'turtleneck',
                    index: 3
                }, {
                    name: 'coat',
                    index: 2
                }]
            },
            // {
            //     index: 14,
            //     path: require("@/assets/models/Girl_Torso/FmaleOutwear_renders1.0004.jpg"),
            //     sex: 1,
            //     materials: [{
            //         name: 'tShirt',
            //         index: 2
            //     }]
            // },
            // {
            //     index: 15,
            //     path: require("@/assets/models/Girl_Torso/FmaleOutwear_renders1.0009.jpg"),
            //     sex: 1,
            //     materials: [{
            //         name: 'sweater',
            //         index: 2
            //     }]
            // },
            {
                index: 16,
                path: require("@/assets/models/Girl_Torso/FmaleOutwear_renders1.0014.jpg"),
                sex: 1,
                materials: [{
                    name: 'sweater',
                    index: 2
                }]
            },
            {
                index: 17,
                path: require("@/assets/models/Girl_Torso/FmaleOutwear_renders1.0019.jpg"),
                sex: 1,
                materials: [{
                    name: 'turtleneck',
                    index: 2
                }]

            },
            {
                index: 18,
                path: require("@/assets/models/Girl_Torso/FmaleOutwear_renders1.0024.jpg"),
                sex: 1,
                materials: [{
                    name: 'openShirt',
                    index: 2
                }]

            },
            {
                index: 19,
                path: require("@/assets/models/Girl_Torso/FmaleOutwear_renders1.0029.jpg"),
                sex: 1,
                materials: [{
                    name: 'closedShirt',
                    index: 2
                }]
            },
            // {
            //     index: 20,
            //     path: require("@/assets/models/Girl_Torso/FmaleOutwear_renders1.0034.jpg"),
            //     sex: 1,
            //     materials: [{
            //         name: 'coat',
            //         index: 3
            //     }, {
            //         name: 'closedShirt',
            //         index: 2
            //     }]
            // },
            {
                index: 21,
                path: require("@/assets/models/Girl_Torso/FmaleOutwear_renders1.0039.jpg"),
                sex: 1,
                materials: [{
                    name: 'coat',
                    index: 2
                }, {
                    name: 'openShirt',
                    index: 3
                }]
            },
            {
                index: 22,
                path: require("@/assets/models/Girl_Torso/FmaleOutwear_renders1.0044.jpg"),
                sex: 1,
                materials: [{
                    name: 'coat',
                    index: 3
                }, {
                    name: 'turtleneck',
                    index: 2
                }]
            },
            {
                index: 23,
                path: require("@/assets/models/Girl_Torso/FmaleOutwear_renders1.0049.jpg"),
                sex: 1,
                materials: [{
                    name: 'jacketWoman1',
                    index: 2
                }]
            },
            {
                index: 24,
                path: require("@/assets/models/Girl_Torso/FmaleOutwear_renders1.0034.jpg"),
                sex: 1,
                materials: [{
                    name: 'coat',
                    index: 4
                }, {
                    name: 'closedShirt',
                    index: 3
                }]
            },
            {
                index: 25,
                path: require("@/assets/models/Girl_Torso/FmaleOutwear__25.jpg"),
                sex: 1,
                materials: [{
                    name: 'closedShirt',
                    index: 3
                }],
            },
            {
                index: DRESS_ID,
                path: require("@/assets/models/Girl_Torso/FmaleOutwear__26.jpg"),
                sex: 1,
                materials: [{
                    name: 'closedShirt',
                    index: 3
                }],
            },
            {
                index: DRESS_ID,
                path: require("@/assets/models/Girl_Torso/Dress.jpg"),
                sex: 1,
                materials: [{
                    name: 'dress',
                    index: 3
                }],
            },
        ],
        SkinLegs: [
            {
                index: 3,
                path: require('@/assets/models/Man_Legs/ManPants_renders1.0004.jpg'),
                sex: 0,
                materials: [{
                    name: 'pants',
                    index: 1
                }]
            },
            {
                index: 2,
                path: require('@/assets/models/Man_Legs/ManPants_renders1.0009.jpg'),
                sex: 0,
                materials: [{
                    name: 'pants',
                    index: 1
                }]
            },
            {
                index: 1,
                path: require('@/assets/models/Man_Legs/ManPants_renders1.0014.jpg'),
                sex: 0,
                materials: [{
                    name: 'pants',
                    index: 1
                }]
            },
            {
                index: 0,
                path: require('@/assets/models/Man_Legs/ManPants_renders1.0019.jpg'),
                sex: 0,
                materials: [{
                    name: 'pants',
                    index: 1
                }]
            },
            {
                index: 4,
                path: require('@/assets/models/Girl_Legs/FemalePants_renders2.0004.jpg'),
                sex: 1,
                materials: [{
                    name: 'pants1',
                    index: 1
                }]
            },
            {
                index: 5,
                path: require('@/assets/models/Girl_Legs/FemalePants_renders2.0009.jpg'),
                sex: 1,
                materials: [{
                    name: 'pants',
                    index: 1
                }]
            },
            {
                index: 6,
                path: require('@/assets/models/Girl_Legs/FemalePants_renders2.0014.jpg'),
                sex: 1,
                materials: [{
                    name: 'pants',
                    index: 1
                }]
            },
            {
                index: 7,
                path: require('@/assets/models/Girl_Legs/FemalePants_renders2.0019.jpg'),
                sex: 1,
                materials: [{
                    name: 'pants',
                    index: 1
                }]
            },
            {
                index: 8,
                path: require('@/assets/models/Girl_Legs/FemalePants_renders2.0024.jpg'),
                sex: 1,
                materials: [{
                    name: 'pants',
                    index: 1
                }]
            },
            {
                index: 9,
                path: require('@/assets/models/Girl_Legs/FemalePants_renders2.0006.jpg'),
                sex: 1,
                materials: [{
                    name: 'pants',
                    index: 1
                }]
            },
            {
                index: 10,
                path: require('@/assets/models/Girl_Legs/FemalePants_renders2.0007.jpg'),
                sex: 1,
                materials: [
                    {
                        name: 'pants',
                        index: 2
                    },
                ]
            },
            {
                index: 11,
                path: require('@/assets/models/Girl_Legs/FemalePants_renders2.0008.jpg'),
                sex: 1,
                materials: [
                    {
                        name: 'pants',
                        index: 2
                    },
                ]
            },
            {
                index: 12,
                path: require('@/assets/models/Girl_Legs/FemalePants_9.jpg'),
                sex: 1,
                materials: [{
                    name: 'pants',
                    index: 2
                }]
            },
            // {
            //     index: 9,
            //     path: require('@/assets/models/Girl_Legs/FemalePants_renders2.0029.jpg'),
            //     sex: 1,
            //     materials: [{
            //         name: 'pants',
            //         index: 1
            //     }]
            // },
        ],
        SkinShoes: [
            {
                index: 5,
                path: require('@/assets/models/Shoes/Boots_renders3.0004.jpg'),
                sex: 1,
                materials: [{
                    name: 'SkinShoes',
                    index: 1
                }]
            },
            {
                index: 8,
                path: require('@/assets/models/Shoes/Boots_renders3.0009.jpg'),
                sex: 1,
                materials: [{
                    name: 'SkinShoes',
                    index: 1
                }]
            },
            {
                index: 7,
                path: require('@/assets/models/Shoes/Boots_renders3.0014.jpg'),
                sex: 1,
                materials: [{
                    name: 'SkinShoes',
                    index: 1
                }]
            },
            {
                index: 9,
                path: require('@/assets/models/Shoes/Boots_renders3.0019.jpg'),
                sex: 1,
                materials: [{
                    name: 'SkinShoes',
                    index: 1
                }]
            },
            {
                index: 6,
                path: require('@/assets/models/Shoes/Boots_renders3.0024.jpg'),
                sex: 1,
                materials: [{
                    name: 'SkinShoes',
                    index: 1
                }]
            },
            {
                index: 10,
                path: require('@/assets/models/Shoes/Boots_renders3.0029.jpg'),
                sex: 1,
                materials: [{
                    name: 'SkinShoes',
                    index: 1
                }]
            },
            {
                index: 2,
                path: require('@/assets/models/Shoes/Boots_renders3.0009.jpg'),
                sex: 0,
                materials: [{
                    name: 'SkinShoes',
                    index: 1
                }]
            },
            {
                index: 1,
                path: require('@/assets/models/Shoes/Boots_renders3.0014.jpg'),
                sex: 0,
                materials: [{
                    name: 'SkinShoes',
                    index: 1
                }]
            },
            {
                index: 3,
                path: require('@/assets/models/Shoes/Boots_renders3.0019.jpg'),
                sex: 0,
                materials: [{
                    name: 'SkinShoes',
                    index: 1
                }]
            },
            {
                index: 0,
                path: require('@/assets/models/Shoes/Boots_renders3.0024.jpg'),
                sex: 0,
                materials: [{
                    name: 'SkinShoes',
                    index: 1
                }]
            },
            {
                index: 4,
                path: require('@/assets/models/Shoes/Boots_renders3.0029.jpg'),
                sex: 0,
                materials: [{
                    name: 'SkinShoes',
                    index: 1
                }]
            },
            {
                index: 25,
                path: require('@/assets/models/Shoes/Boots_renders3.0007.jpg'),
                sex: 0,
                materials: [{
                    name: 'SkinShoes',
                    index: 1
                }]
            },
            {
                index: 11,
                path: require('@/assets/models/Shoes/Boots_renders3.0007.jpg'),
                sex: 1,
                materials: [{
                    name: 'SkinShoes',
                    index: 1
                }]
            },
        ]
    }
}



const hairColors = [
    '#f7c090',
    '#8b6548',
    '#ffdba7',
    '#ffb601',
    '#3f3f3f',
    '#ff9b8d',
    '#fff790',
    '#7aff77',
    '#8cfff6',
    '#96adff',
    '#ffa3f8',
    '#d9d9d9',
]

export const clothesColors = {
    SkinBeard: hairColors,
    SkinMustache: hairColors,
    SkinHair: hairColors,
    tShirt: [
        '#757575',
        '#593620',
        '#59524d',
        '#4b2829',
        '#c7a28a',
        '#6c2417',
        '#889cb0',
        '#ceaa7e',
        '#353535',
        '#323045'
    ],
    sweater: [
        '#474747',
        '#7c7c7c',
        '#597289',
        '#271212',
        '#b99362',
        '#252435',
        '#3f292a',
        '#2a3832',
        '#551a10',
        '#313b4d'
    ],
    dress: [
        '#898989',
        '#757f8e',
        '#ffffff',
        '#3f3730',
        '#00172c',
        '#190001',
        '#18271e',
        '#636363',
        '#4d3b21',
        '#525941',
    ],
    turtleneck: ['#474747', '#7c7c7c', '#597289', '#271212', '#b99362', '#252435', '#3f292a', '#2a3832', '#63503f', '#313b4d'],
    openShirt: ['#595959', '#e7e7e7', '#f3f3f3', '#a79484', '#323a51', '#3f292a', '#293f3c', '#597289', '#b99362', '#101010'],
    closedShirt: ['#595959', '#d3ebff', '#f3f3f3', '#a79484', '#323a51', '#3f292a', '#293f3c', '#597289', '#b99362', '#101010'],
    tie: ['#e1e1e1', '#634d3b', '#631e21', '#191919', '#32554b', '#0a1943', '#554a5b', '#3f506a', '#7c7c7c', '#950000'],
    vest: ['#d3d3d3', '#757f8f', '#191919', '#7b6d60', '#1d2c50', '#351416', '#1b2b22', '#636363', '#8b6c41', '#848f69'],
    coat: ['#000000', '#757f8f', '#bcbcbc', '#3f3730', '#00172c', '#190000', '#18271e', '#636363', '#4d3b21', '#525940'],
    jacket: ['#d3d3d3', '#757f8f', '#191919', '#7b6d60', '#1d2c50', '#351416', '#1b2b22', '#636363', '#8b6c41', '#848f69'],
    jacketWoman1: ['#454545', '#6c6c6c', '#005f89', '#590000', '#b99362', '#300000', '#898989', '#2a3832', '#551a10', '#313b4d'],
    jacketWoman2: ['#454545', '#6c6c6c', '#597289', '#271212', '#b99362', '#300000', '#3f292a', '#2a3832', '#551a10', '#313b4d'],
    pants1: ['#454545', '#e1e1e1', '#597289', '#271212', '#b99362', '#252435', '#3f292a', '#2a3832', '#551a10', '#313b4d'],
    pants: ['#000000', '#465a74', '#bcbcbc', '#211c18', '#00172c', '#190000', '#18271e', '#636363', '#4d3b21', '#1c1e14'],
    SkinShoes: ['#000000', '#324055', '#bcbcbc', '#4a4038', '#003e6a', '#490000', '#21342a', '#6c6c6c', '#956a00', '#3c3f2e'],
}


export const newDefaultModel = {
    SkinHead: '0-0-0',
    SkinHair: '0-0-0',
    SkinBeard: '0-0-0',
    SkinMustache: '0-0-0',
    SkinAccessory: '0-0-0',
    SkinTorso: '1-1-0',
    SkinLegs: '1-1-0',
    SkinShoes: '1-0-0',
    Sex: '0',
    Character: ""
}

export const defaultWomenModel = {
    SkinHead: "10-10-5",
    SkinHair: "12-0-2",
    SkinBeard: "0-0-0",
    SkinMustache: "0-0-0",
    SkinAccessory: "0-0-0",
    SkinTorso: "23-10-1-0",
    SkinLegs: "9-10-1-0",
    SkinShoes: "5-2-0",
    Sex: "1",
    Character: ""
}

