export default [
  {
    "name": "Видеоаналитика",
    "category": "sber",
    "description": "Начнем наше путешествие со знакомства с технологией «Видеоаналитика». О её преимуществах и сферах применения вы можете узнать на этом стенде.",
    "imgUrl": require("@/assets/pavilions/sber/3ea473182d2bc7f38fdc4f060426db.png"),
    "Id": "3ea473182d2bc7f38fdc4f060426db1",
  },
  {
    "name": "Безопасный город",
    "category": "sber",
    "description": "А сейчас давайте прогуляемся по умным улицам города будущего – СберСити! Фонари в нём загораются ровно в тот момент, когда стемнеет. Здесь уютно и безопасно в любое время дня и ночи. И, конечно, для покупок во время прогулки при себе не обязательно иметь кошелёк и банковские карты. В городе будущего даже дворы станут умными! В таком месте будут отлично проводить время дети, взрослые, бабушки и дедушки.",
    "imgUrl": require("@/assets/pavilions/sber/7032653411d54ddb6a0a1de82fda51f.png"),
    "Id": "7032653411d54ddb6a0a1de82fda51f",
  },
  {
    "name": "Безопасный транспорт",
    "category": "sber",
    "description": "Новый беспилотный транспорт доставит в нужное место надёжно и вовремя, а системы безопасности защитят от происшествий на дороге.",
    "imgUrl": require("@/assets/pavilions/sber/3fa548c4f43d2dfa950eaadc0dbffbf.png"),
    "Id": "3fa548c4f43d2dfa950eaadc0dbffbf",
  },
  {
    "name": "Центр управления",
    "category": "sber",
    "description": "Добро пожаловать в центр управления безопасностью нашего города будущего. Обратите внимание на верхние экраны – здесь демонстрируются технологии видеонаблюдения с использованием искусственного интеллекта. И эти технологии уже сегодня обеспечивают нашу безопасность.",
    "imgUrl": require("@/assets/pavilions/sber/ef938b67f5ab56fbd1a2beedd3a5710.png"),
    "Id": "ef938b67f5ab56fbd1a2beedd3a5710",
  },
  {
    "name": "CUB3",
    "category": "sber",
    "description": "Здесь представлена арт инсталляция CUB_3 от компании по управлению цифровыми рисками Bi.ZONE, которая исследует тему объединения цифрового и физического мира. Куб символизирует собой собирательный образ новых технологий, которые стимулируют прогресс человечества на пути к светлому и безопасному будущему.",
    "imgUrl": require("@/assets/pavilions/sber/58cadd8e6df044291c195d0d43f4f36.png"),
    "Id": "58cadd8e6df044291c195d0d43f4f36",
  },
  {
    "name": "Финансовая безопасность",
    "category": "sber",
    "description": "В этой части нашего города будущего можно узнать о том, как противостоять кибермошенникам и защитить свои деньги.",
    "imgUrl": require("@/assets/pavilions/sber/1d646ec5592065e7770cdeaa56ec5b1.png"),
    "Id": "1d646ec5592065e7770cdeaa56ec5b1",
  },
  {
    "name": "",
    "category": "sber",
    "description": "Добро пожаловать в зону веселья! Здесь во время выставки форума можно было порадовать себя покупкой в СберШопе или МегаМаркете, получить футболку с уникальным принтом от Kandinsky или перекусить в кафе. А самые маленькие посетители могли вдоволь наиграться вместе со СберКотом и любимыми героями от «СоюзМультФильм». Также дети могли добыть настоящие драгоценности вместе с Дримми – талисманом курорта Сбера «МРИЯ».",
    "imgUrl": "",
    "Id": "e0e818d20e474caaaede746446afc1fb"
  },
  {
    "name": "",
    "category": "sber",
    "description": "Любишь спокойный отдых? Во время выставки-форума «Россия» здесь начиналось виртуальное путешествие на курорт Сбера Мрия с японским садом, карпами в пруду и свежим воздухом. ",
    "imgUrl": require("@/assets/pavilions/sber/c4524a1f9fe398fe40f2df01364fceac.png"),
    "Id": "81f03a30eb624c29c0d21e599d9b27fb"
  },
  {
    "name": "",
    "category": "sber",
    "description": "Фанатеешь от экстрима? Тогда тебе на круглогодичный курорт Сбера—Манжерок! Там можно подняться в горы и прокатиться по скоростной трассе на лыжах или велосипеде.",
    "imgUrl": require("@/assets/pavilions/sber/c4524a1f9fe398fe40f2df01364fceac.png"),
    "Id": "c4524a1f9fe398fe40f2df01364fceac"
  },
  {
    "name": "Моя Страна",
    "category": "sber",
    "description": "А здесь можно было совершить виртуальную экскурсию по России вместе с Русским географическим обществом, а также посмотреть видеоролики от курортов Сбера.",
    "imgUrl": require("@/assets/pavilions/sber/d1b1f8b2b2a0f748b19cf6cb7b154110.png"),
    "Id": "d1b1f8b2b2a0f748b19cf6cb7b154110"
  },
  {
    "name": "Виноградники AI",
    "category": "sber",
    "description": "Может ли искусственный интеллект работать на ферме? Почему бы и нет! В Винном парке курорта Сбера «МРИЯ» маленький робот следит, чтобы виноград рос правильно, был спелым и ничем не болел. Да, у высоких технологий есть и вполне земные задачи.",
    "imgUrl": require("@/assets/pavilions/sber/aec94eb3f90695fd858aeb377377f94b.png"),
    "Id": "aec94eb3f90695fd858aeb377377f94b"
  },
  {
    "name": "",
    "category": "sber",
    "description": "Сбер–это не только серьёзные технологии, но ещё и развлечения. В этом зале можно было сфотографироваться с Чебурашкой, сыграть в компьютерную игру и даже создать свои картинки или музыку с помощью нейросети!",
    "imgUrl": require("@/assets/pavilions/sber/b2a969d1773c78c4fe2699604ee2d223.png"),
    "Id": "b2a969d1773c78c4fe2699604ee2d223"
  },
  {
    "name": "JAZZ VR",
    "category": "sber",
    "description": "Все больше и больше человечество проводит время в цифровом мире. И перспективы развития метавселенных поистине безграничны. Сбер не отстает от общей тенденции и разработал свою метавселенную. С помощью «Jazz VR» можно будет учиться, работать и творить.",
    "imgUrl": require("@/assets/pavilions/sber/c3698af900ea854baaa133fcec3d14b9.png"),
    "Id": "c3698af900ea854baaa133fcec3d14b9"
  },
  {
    "name": "Фабрика контента",
    "category": "sber",
    "description": "Эта комната, где во время проведения форума-выставки «Россия» можно было создавать контент с помощью технологий искусственного интеллекта. Гость мог записать трек, текст для которого написал GigaСhat, создать пост или сгенерировать картинку с помощью нейросети Kandinsky.",
    "imgUrl": require("@/assets/pavilions/sber/ce797029780041678204db0ef9565671.png"),
    "Id": "ce797029780041678204db0ef9565671"
  },
]