
<script setup>
import { onBeforeUnmount, onMounted, shallowRef, watch } from 'vue';

const timer = shallowRef(null);
const showModal = shallowRef(false);

onMounted(() => {
  document.addEventListener('AudioGuide', audioGuideListener);
  document.addEventListener('AudioGuideOverlap', interactiveObjectOverlapListener);
  window.addEventListener('keydown', keyListener);
});

onBeforeUnmount(() => {
  window.removeEventListener('keydown', keyListener);
  window.removeEventListener('AudioGuide', audioGuideListener);
  window.removeEventListener('AudioGuideOverlap', interactiveObjectOverlapListener);

  if (timer.value) {
    clearInterval(timer.value);
  }
});

function keyListener(event) {
  if (event.keyCode === 69) {
    showModal.value = false;
  }
}

function audioGuideListener() {
  showModal.value = false;
  console.log('dispatch AudioGuideEndOverlap true')
  document.dispatchEvent(new CustomEvent('AudioGuideEndOverlap', { detail: true }));
}

function interactiveObjectOverlapListener({ detail }) {
  showModal.value = detail;
  console.log('dispatch AudioGuideEndOverlap false')
  document.dispatchEvent(new CustomEvent('AudioGuideEndOverlap', { detail: false }));
}


watch(showModal, (value) => {
  if (!value) {
    return;
  }

  timer.value = setInterval(() => {
    showModal.value = false;
    clearInterval(timer.value);
  }, 10000);
});
</script>


<template>
  <Transition name="fade">
    <div v-if="showModal" class="shell">
      <div class="modal">
        <p class="letter">Е</p>
        <p class="info">В павильоне есть аудиогид: нажмите <span>“E”</span>, чтобы его запустить</p>
      </div>
    </div>
  </Transition>
</template>

<style scoped>
.shell {
  align-items: flex-start;
  justify-content: center;
  border-radius: 2.75rem;
  width: 28rem;
  height: unset;
  padding: 0.5rem;
  backdrop-filter: blur(8px);
  background: rgba(157, 196, 248, 0.3);
  left: calc(50% - 14rem);
  top: 1rem;
}

.modal {
  background: #003099;
  border-radius: 2.75rem;
  display: flex;
  gap: 0.875rem;
  align-items: center;
  flex-direction: row;
  padding: 0.75rem;
  margin: 0;
}

.letter {
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  background: #d72d41;
  font-weight: 900;
  font-size: 2.5rem;
  color: #fff;
  border-radius: 100%;
  font-family: "Inter", sans-serif;
}

.info {
  font-weight: 400;
  font-size: 1.12rem;
  letter-spacing: -0.01em;
  color: #fff;
}

.info span {
  font-weight: 700;
}

@media (max-width: 1000px) {
  .shell {
    display: none;
  }
}
</style>
